import { useCurrentUserRole } from './useCurrentUserRole';

const roleHierarchy = [
  'guest',
  'user',
  'admin',
  // TODO: add consultant to this list
  'super admin',
  'account owner',
  'root',
] as const;

type Role = (typeof roleHierarchy)[number];

export const useUserPermissions = () => {
  const { currentRole } = useCurrentUserRole();

  const getRoleLevel = (role: string): number => {
    const index = roleHierarchy.indexOf(role.toLowerCase() as Role);
    return index === -1 ? 0 : index;
  };

  const hasPermission = (requiredRole: string): boolean => {
    const userRoleLevel = getRoleLevel(currentRole?.role ?? '');
    const requiredRoleLevel = getRoleLevel(requiredRole);
    return userRoleLevel >= requiredRoleLevel;
  };

  return { hasPermission };
};
