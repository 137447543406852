import { useState, useCallback, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { Button } from '../../../common/catalyst/button';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useForm, FormProvider } from 'react-hook-form';
import { useUserPermissions } from '../../../../hooks/useUserPermissions';
import { useOnboarding } from '../../../../hooks/useOnboarding';
import { User, UserRole, Organisation } from '../../../../types';
import {
  NameStep,
  TitleStep,
  TeamStep,
  OrganisationNameStep,
  OrganisationTypeStep,
  OrganisationSizeStep,
  OrganisationCausesStep,
  OrganisationColourStep,
} from './steps';
import { OnboardingFormInputs } from '../../../../types/OnboardingFormInputs';

interface OnboardingModalProps {
  user: User;
  currentRole: UserRole;
  currentOrganisation: Organisation;
  onComplete: () => void;
}

export function OnboardingModal({
  user,
  currentRole,
  currentOrganisation,
  onComplete,
}: OnboardingModalProps) {
  const { onboardUser } = useOnboarding();
  const { hasPermission } = useUserPermissions();
  const [isLoading, setIsLoading] = useState(false);

  const initialCauses = currentOrganisation.causes?.reduce(
    (acc, cause) => ({
      ...acc,
      [cause]: true,
    }),
    {} as { [key: string]: boolean }
  );

  const form = useForm<OnboardingFormInputs>({
    defaultValues: {
      user: {
        id: user.id,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
      },
      userRole: {
        id: currentRole.id,
        title: currentRole.title || '',
        team: currentRole.team || '',
        otherTeam: '',
      },
      organisation: hasPermission('admin')
        ? {
            id: currentOrganisation.id,
            name: currentOrganisation.name || '',
            type: currentOrganisation.type || '',
            causes: initialCauses,
            size: currentOrganisation.size || '',
            colour: currentOrganisation.colour || '',
          }
        : undefined,
    },
  });
  const { watch } = form;

  const isAdmin = hasPermission('admin');
  const organisationType = watch('organisation.type') || '';

  const getSteps = useCallback(() => {
    const baseSteps = [
      { id: 'name', name: 'Your name', component: <NameStep /> },
    ];

    const adminSteps = [
      {
        id: 'organisationName',
        name: 'Organisation',
        component: <OrganisationNameStep />,
      },
      {
        id: 'organisationType',
        name: 'Organisation type',
        component: <OrganisationTypeStep />,
      },
      {
        id: 'organisationSize',
        name: 'Organisation size',
        component: <OrganisationSizeStep />,
      },
      {
        id: 'organisationColour',
        name: 'Colour',
        component: <OrganisationColourStep />,
      },
    ];

    const ForPurposeStep = {
      id: 'organisationCauses',
      name: 'Causes',
      component: <OrganisationCausesStep />,
    };

    const finalSteps = [
      { id: 'title', name: 'Your title', component: <TitleStep /> },
      { id: 'team', name: 'Your team', component: <TeamStep /> },
    ];

    if (isAdmin) {
      return [
        ...baseSteps,
        ...adminSteps,
        ...(['not-for-profit', 'social enterprise'].includes(organisationType)
          ? [ForPurposeStep]
          : []),
        ...finalSteps,
      ];
    } else {
      return [...baseSteps, ...finalSteps];
    }
  }, [isAdmin, organisationType]);

  const [steps, setSteps] = useState(getSteps());
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    setSteps(getSteps);
  }, [getSteps]);

  const getStepStatus = (
    index: number
  ): 'complete' | 'current' | 'upcoming' => {
    if (index < currentStep) return 'complete';
    if (index === currentStep) return 'current';
    return 'upcoming';
  };

  const handleNext = async () => {
    const isValid = await form.trigger();
    if (isValid && currentStep < steps.length - 1) {
      setCurrentStep((prevStep) => prevStep + 1);
    } else if (currentStep === steps.length - 1) {
      const formData = form.getValues();
      handleComplete(formData);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const handleClose = () => {
    onComplete();
  };

  const isStepValid = () => {
    switch (steps[currentStep].id) {
      case 'name': {
        const firstName = watch('user.firstName');
        const lastName = watch('user.lastName');
        return firstName && lastName;
      }
      case 'organisationName':
        return !!watch('organisation.name');
      case 'organisationType':
        return !!watch('organisation.type');
      case 'organisationSize':
        return !!watch('organisation.size');
      case 'organisationColour':
        return !!watch('organisation.colour');
      case 'organisationCauses':
        return true;
      case 'title':
        return !!watch('userRole.title');
      case 'team': {
        const team = watch('userRole.team');
        const otherTeam = watch('userRole.otherTeam');
        return team && (team !== 'Other' || otherTeam);
      }
      default:
        return true;
    }
  };

  const handleComplete = async (formData: OnboardingFormInputs) => {
    setIsLoading(true);
    try {
      await onboardUser(formData);
      onComplete();
    } catch (error) {
      console.error('Error updating data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={true} onClose={() => {}}>
      <div className="fixed inset-0 z-50">
        <FormProvider {...form}>
          <Dialog.Panel className="w-full h-full bg-white flex flex-col p-4 sm:p-6">
            <div className="flex justify-between items-center mb-4 p-1">
              <nav aria-label="Progress" className="flex-grow">
                <ol role="list" className="flex items-center">
                  {steps.map((step, index) => (
                    <li
                      key={step.name}
                      className={`${index !== steps.length - 1 ? 'pr-4 sm:pr-8' : ''} relative`}
                    >
                      {(() => {
                        const status = getStepStatus(index);
                        switch (status) {
                          case 'complete':
                            return (
                              <>
                                <div
                                  aria-hidden="true"
                                  className="absolute inset-0 flex items-center"
                                >
                                  <div className="h-0.5 w-full bg-zinc-600" />
                                </div>
                                <a className="relative flex h-6 w-6 items-center justify-center rounded-full bg-zinc-600 hover:bg-zinc-900">
                                  <CheckIcon
                                    className="h-4 w-4 text-white"
                                    aria-hidden="true"
                                  />
                                  <span className="sr-only">{step.name}</span>
                                </a>
                              </>
                            );
                          case 'current':
                            return (
                              <>
                                <div
                                  aria-hidden="true"
                                  className="absolute inset-0 flex items-center"
                                >
                                  <div className="h-0.5 w-full bg-gray-200" />
                                </div>
                                <a
                                  aria-current="step"
                                  className="relative flex h-6 w-6 items-center justify-center rounded-full border-2 border-zinc-600 bg-white"
                                >
                                  <span
                                    className="h-2 w-2 rounded-full bg-zinc-600"
                                    aria-hidden="true"
                                  />
                                  <span className="sr-only">{step.name}</span>
                                </a>
                              </>
                            );
                          case 'upcoming':
                            return (
                              <>
                                <div
                                  aria-hidden="true"
                                  className="absolute inset-0 flex items-center"
                                >
                                  <div className="h-0.5 w-full bg-gray-200" />
                                </div>
                                <a className="group relative flex h-6 w-6 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400">
                                  <span
                                    className="h-2 w-2 rounded-full bg-transparent group-hover:bg-gray-300"
                                    aria-hidden="true"
                                  />
                                  <span className="sr-only">{step.name}</span>
                                </a>
                              </>
                            );
                        }
                      })()}
                    </li>
                  ))}
                </ol>
              </nav>
              <button
                onClick={handleClose}
                disabled={currentStep !== steps.length - 1}
                className={`ml-4 p-2 rounded-full ${
                  currentStep === steps.length - 1
                    ? 'text-gray-600 hover:bg-gray-100'
                    : 'text-gray-300'
                }`}
              >
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="flex-grow overflow-y-auto flex items-center">
              <div className="w-full">{steps[currentStep].component}</div>
            </div>
            <div className="mt-6 flex justify-between p-1">
              {currentStep > 0 ? (
                <Button
                  onClick={handlePrevious}
                  plain
                  className="flex items-center"
                >
                  <svg
                    className="stroke-current"
                    data-slot="icon"
                    viewBox="0 0 16 16"
                    fill="none"
                    aria-hidden="true"
                  >
                    <path
                      d="M2.75 8H13.25M2.75 8L5.25 5.5M2.75 8L5.25 10.5"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Back
                </Button>
              ) : (
                <div />
              )}
              <Button
                onClick={handleNext}
                plain
                className="flex items-center"
                disabled={!isStepValid()}
                loading={isLoading}
              >
                {currentStep === steps.length - 1 ? 'Done' : 'Continue'}
                <svg
                  className="stroke-current"
                  data-slot="icon"
                  viewBox="0 0 16 16"
                  fill="none"
                  aria-hidden="true"
                >
                  <path
                    d="M13.25 8L2.75 8M13.25 8L10.75 10.5M13.25 8L10.75 5.5"
                    strokeWidth={1.5}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Button>
            </div>
          </Dialog.Panel>
        </FormProvider>
      </div>
    </Dialog>
  );
}
