import React from 'react';

interface SocialLoginButtonProps {
  href?: string;
  iconSrc: string;
  altText: string;
  children: React.ReactNode;
  onClick?: () => void;
}

export function SocialLoginButton({
  href,
  iconSrc,
  altText,
  children,
  onClick,
}: SocialLoginButtonProps) {
  const Tag = href ? 'a' : 'button';

  return (
    <Tag
      href={href}
      onClick={onClick}
      className="flex w-full items-center justify-center gap-3 rounded-xl bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-graphite-200 hover:bg-graphite-25 focus-visible:ring-transparent"
    >
      <img src={iconSrc} alt={altText} className="h-5 w-5" />
      <span className="text-sm font-medium leading-6">{children}</span>
    </Tag>
  );
}
