import { useEffect, useState } from 'react';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { Input } from '../../common/catalyst/input';
import { Select } from '../../common/catalyst/select';
import { Button } from '../../common/catalyst/button';
import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { useToastStore } from '../../../stores/toastStore';
import { useCurrentOrganisation } from '../../../hooks/useCurrentOrganisation';
import { useOrganisation } from '../../../hooks/useOrganisation';
import { useUserPermissions } from '../../../hooks/useUserPermissions';
import { EditDomainDialog } from '../dialogs/EditDomainDialog';
import { OrganisationFormValues } from '../../../types/OrganisationFormValues';
import { Organisation, OrganisationCause } from '../../../types/Organisation';

type CauseId =
  | 'animal_welfare'
  | 'arts_and_culture'
  | 'children_and_youth'
  | 'community_development'
  | 'disability_services'
  | 'disaster_relief'
  | 'education_and_literacy'
  | 'elderly_care'
  | 'environment_and_conservation'
  | 'food_security'
  | 'health_and_medical_research'
  | 'homelessness_and_housing'
  | 'international_development'
  | 'lgbtqia_rights'
  | 'mental_health'
  | 'poverty_alleviation'
  | 'racial_equality'
  | 'refugees_and_immigration'
  | 'religion_and_spirituality'
  | 'sports_and_recreation'
  | 'veterans_and_military'
  | 'womens_empowerment';

const causes = [
  {
    id: 'animal_welfare',
    name: 'Animal welfare',
    description: 'Promote animal rights and well-being',
  },
  {
    id: 'arts_and_culture',
    name: 'Arts and culture',
    description: 'Support and preserve artistic and cultural expressions',
  },
  {
    id: 'children_and_youth',
    name: 'Children and youth',
    description: 'Improve the lives and opportunities of young people',
  },
  {
    id: 'community_development',
    name: 'Community development',
    description: 'Strengthen and improve local communities',
  },
  {
    id: 'disability_services',
    name: 'Disability services',
    description: 'Support inclusion and accessibility',
  },
  {
    id: 'disaster_relief',
    name: 'Disaster relief',
    description: 'Provide assistance in natural or man-made disasters',
  },
  {
    id: 'education_and_literacy',
    name: 'Education and literacy',
    description: 'Promote access to education for all',
  },
  {
    id: 'elderly_care',
    name: 'Elderly care',
    description: 'Improve the quality of life for seniors',
  },
  {
    id: 'environment_and_conservation',
    name: 'Environment and conservation',
    description: 'Protect nature and combat climate change',
  },
  {
    id: 'food_security',
    name: 'Food security and hunger relief',
    description: 'Combat hunger and promote access to healthy food',
  },
  {
    id: 'health_and_medical_research',
    name: 'Health and medical research',
    description: 'Improve public health and advance medical research',
  },
  {
    id: 'homelessness_and_housing',
    name: 'Homelessness and housing',
    description: 'Provide shelter and sustainable housing solutions',
  },
  {
    id: 'international_development',
    name: 'International development',
    description: 'Promote social and economic progress globally',
  },
  {
    id: 'lgbtqia_rights',
    name: 'LGBTQIA+ rights and advocacy',
    description:
      'Advocate for equality and inclusion for the LGBTQIA+ community',
  },
  {
    id: 'mental_health',
    name: 'Mental health and wellbeing',
    description: 'Support mental well-being and combat stigmatization',
  },
  {
    id: 'poverty_alleviation',
    name: 'Poverty alleviation',
    description: 'Reduce economic and social inequalities',
  },
  {
    id: 'racial_equality',
    name: 'Racial and ethnic equality',
    description: 'Combat racism and promote equity',
  },
  {
    id: 'refugees_and_immigration',
    name: 'Refugees and immigration',
    description: 'Support the rights and integration of migrants and refugees',
  },
  {
    id: 'religion_and_spirituality',
    name: 'Religion and spirituality',
    description: 'Promote interfaith understanding and respect',
  },
  {
    id: 'sports_and_recreation',
    name: 'Sports and recreation',
    description: 'Encourage physical activity and well-being through sports',
  },
  {
    id: 'veterans_and_military',
    name: 'Veterans and military families',
    description: 'Support those who have served and their families',
  },
  {
    id: 'womens_empowerment',
    name: "Women's empowerment and gender equality",
    description: "Promote gender equality and women's rights",
  },
];

export function OrganisationDetails() {
  const { show } = useToastStore();
  const { currentOrganisation } = useCurrentOrganisation();
  const { updateOrganisation } = useOrganisation(currentOrganisation?.id || '');
  const { hasPermission } = useUserPermissions();

  const [isEditDomainOpen, setIsEditDomainOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { register, control, handleSubmit, reset, setValue } =
    useForm<OrganisationFormValues>({
      defaultValues: {
        name: currentOrganisation?.name || '',
        domain: currentOrganisation?.domain || '',
        type: currentOrganisation?.type || '',
        size: currentOrganisation?.size || '',
        causes:
          currentOrganisation?.causes?.reduce(
            (acc, cause) => ({ ...acc, [cause]: true }),
            {} as { [key in OrganisationCause]: boolean }
          ) || {},
        organisationColour: currentOrganisation?.colour || '',
        contactName: currentOrganisation?.contactName || '',
        contactEmail: currentOrganisation?.contactEmail || '',
        contactPhone: currentOrganisation?.contactPhone || '',
        website: currentOrganisation?.website || '',
        addressLine1: currentOrganisation?.addressLine1 || '',
        addressLine2: currentOrganisation?.addressLine2 || '',
        suburb: currentOrganisation?.suburb || '',
        postcode: currentOrganisation?.postcode || '',
        state: currentOrganisation?.state || '',
        country: currentOrganisation?.country || '',
      },
    });

  const organisationType = useWatch({
    control,
    name: 'type',
  });

  const onSubmit = async (data: OrganisationFormValues) => {
    if (!currentOrganisation) return;

    try {
      setIsSaving(true);
      const updatedData: Partial<Organisation> = {
        name: data.name,
        ...(data.type && { type: data.type }),
        ...(data.size && { size: data.size }),
        causes: Object.entries(data.causes)
          .filter(([, isSelected]) => isSelected)
          .map(([key]) => key as OrganisationCause),
        colour: data.organisationColour,
        contactName: data.contactName || undefined,
        contactEmail: data.contactEmail || undefined,
        contactPhone: data.contactPhone || undefined,
        website: data.website || undefined,
        addressLine1: data.addressLine1 || undefined,
        addressLine2: data.addressLine2 || undefined,
        suburb: data.suburb || undefined,
        postcode: data.postcode || undefined,
        state: data.state || undefined,
        country: data.country || undefined,
      };

      await updateOrganisation({
        organisationId: currentOrganisation.id,
        updates: updatedData,
      });
      show('Organisation saved', 'success');
    } catch (err) {
      console.error(err);
      show('Failed to update organisation', 'error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleSaveDomain = async (newDomain: string) => {
    try {
      if (currentOrganisation) {
        await updateOrganisation({
          organisationId: currentOrganisation.id,
          updates: { domain: newDomain },
        });
        setValue('domain', newDomain);
        setIsEditDomainOpen(false);
        show('Domain successfully updated', 'success');
      } else {
        console.error('Current organisation is not set');
        show('Failed to update domain', 'error');
      }
    } catch (error) {
      console.error('Failed to update domain:', error);
      show('Failed to update domain', 'error');
    }
  };

  useEffect(() => {
    if (currentOrganisation) {
      reset({
        name: currentOrganisation.name,
        domain: currentOrganisation.domain,
        type: currentOrganisation.type,
        size: currentOrganisation.size,
        causes: currentOrganisation.causes?.reduce(
          (acc, cause) => ({ ...acc, [cause]: true }),
          {}
        ),
        organisationColour: currentOrganisation.colour,
        contactName: currentOrganisation.contactName,
        contactEmail: currentOrganisation.contactEmail,
        contactPhone: currentOrganisation.contactPhone,
        website: currentOrganisation.website,
        addressLine1: currentOrganisation.addressLine1,
        addressLine2: currentOrganisation.addressLine2,
        suburb: currentOrganisation.suburb,
        postcode: currentOrganisation.postcode,
        state: currentOrganisation.state,
        country: currentOrganisation.country,
      });
    }
  }, [currentOrganisation, reset]);

  useEffect(() => {
    if (organisationType === 'company' || organisationType === 'other') {
      // Clear all causes
      causes.forEach((cause) => {
        setValue(`causes.${cause.id}` as `causes.${CauseId}`, false);
      });
    }
  }, [organisationType, setValue]);

  if (!hasPermission('admin')) {
    return (
      <div>
        You don&apos;t have permission to view or edit organisation details.
      </div>
    );
  }

  const colorMatrix = [
    [
      { name: 'Rouge', value: 'red-500', color: 'bg-red-500' },
      { name: 'Orange', value: 'orange-500', color: 'bg-orange-500' },
      { name: 'Jaune', value: 'yellow-500', color: 'bg-yellow-500' },
      { name: 'Vert', value: 'green-500', color: 'bg-green-500' },
      { name: 'Sarcelle', value: 'teal-500', color: 'bg-teal-500' },
      { name: 'Bleu', value: 'blue-500', color: 'bg-blue-500' },
      { name: 'zinc', value: 'zinc-500', color: 'bg-zinc-500' },
      { name: 'Violet', value: 'violet-500', color: 'bg-violet-500' },
    ],
    [
      { name: 'Rouge foncé', value: 'red-700', color: 'bg-red-700' },
      { name: 'Orange foncé', value: 'orange-700', color: 'bg-orange-700' },
      { name: 'Jaune foncé', value: 'yellow-700', color: 'bg-yellow-700' },
      { name: 'Vert foncé', value: 'green-700', color: 'bg-green-700' },
      { name: 'Sarcelle foncé', value: 'teal-700', color: 'bg-teal-700' },
      { name: 'Bleu foncé', value: 'blue-700', color: 'bg-blue-700' },
      { name: 'zinc foncé', value: 'zinc-700', color: 'bg-zinc-700' },
      { name: 'Violet foncé', value: 'violet-700', color: 'bg-violet-700' },
    ],
    [
      { name: 'Rouge très foncé', value: 'red-900', color: 'bg-red-900' },
      {
        name: 'Orange très foncé',
        value: 'orange-900',
        color: 'bg-orange-900',
      },
      { name: 'Jaune très foncé', value: 'yellow-900', color: 'bg-yellow-900' },
      { name: 'Vert très foncé', value: 'green-900', color: 'bg-green-900' },
      { name: 'Sarcelle très foncé', value: 'teal-900', color: 'bg-teal-900' },
      { name: 'Bleu trs foncé', value: 'blue-900', color: 'bg-blue-900' },
      { name: 'zinc très foncé', value: 'zinc-900', color: 'bg-zinc-900' },
      {
        name: 'Violet très foncé',
        value: 'violet-900',
        color: 'bg-violet-900',
      },
    ],
  ];

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-12">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-6">
          <div className="md:col-span-2">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Basic information
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              This information will be used to customise your experience and
              improve our services for you.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-4">
            <div className="sm:col-span-3">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Organisation name
              </label>
              <div className="mt-2">
                <Input {...register('name')} id="name" className="w-full" />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="domain"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Domain
              </label>
              <div className="mt-2 flex">
                <Input
                  {...register('domain')}
                  id="domain"
                  type="url"
                  className="flex-grow"
                  disabled
                />
                <Button
                  type="button"
                  className="ml-3 whitespace-nowrap"
                  outline
                  onClick={() => setIsEditDomainOpen(true)}
                >
                  Edit
                </Button>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="type"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Organisation type
              </label>
              <div className="mt-2">
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="type"
                      className="w-full"
                      value={field.value || ''}
                    >
                      <option value="not-for-profit">Not-for-profit</option>
                      <option value="social enterprise">
                        Social enterprise
                      </option>
                      <option value="company">Company</option>
                      <option value="other">Other</option>
                    </Select>
                  )}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="size"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Organisation size
              </label>
              <div className="mt-2">
                <Controller
                  name="size"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      id="size"
                      className="w-full"
                      value={field.value || 'startup'}
                    >
                      <option value="startup">Startup (1-9 employees)</option>
                      <option value="small">Small (10-49 employees)</option>
                      <option value="medium">Medium (50-249 employees)</option>
                      <option value="large">Large (250-999 employees)</option>
                      <option value="enterprise">
                        Enterprise (1000+ employees)
                      </option>
                    </Select>
                  )}
                />
              </div>
            </div>

            <div className="col-span-full">
              <label
                htmlFor="organisationColour"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Organisation colour
              </label>
              <div className="mt-2">
                <Controller
                  name="organisationColour"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup {...field} className="mt-2 space-y-2">
                      {colorMatrix.map((row, rowIndex) => (
                        <div key={rowIndex} className="flex gap-2">
                          {row.map((option) => (
                            <RadioGroup.Option
                              key={option.value}
                              value={option.value}
                              className={`${option.color} relative flex cursor-pointer items-center justify-center rounded-full focus:outline-none`}
                            >
                              {({ checked }) => (
                                <>
                                  <span
                                    className={`flex h-8 w-8 items-center justify-center rounded-full ${
                                      checked
                                        ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-zinc-300'
                                        : ''
                                    }`}
                                    aria-hidden="true"
                                  >
                                    {checked && (
                                      <span className="text-white">
                                        <CheckIcon className="h-6 w-6" />
                                      </span>
                                    )}
                                  </span>
                                  <span className="sr-only">{option.name}</span>
                                </>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      ))}
                    </RadioGroup>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        {(organisationType === 'not-for-profit' ||
          organisationType === 'social enterprise') && (
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-6">
            <div className="md:col-span-2">
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Supported causes
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Select the causes that your organisation supports.
              </p>
            </div>
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 md:col-span-4">
              <div className="col-span-full">
                <div className="mt-4 pr-4">
                  <div className="space-y-5">
                    {causes.map((cause) => (
                      <div key={cause.id} className="relative flex items-start">
                        <div className="flex h-6 items-center">
                          <input
                            id={cause.id}
                            type="checkbox"
                            {...register(
                              `causes.${cause.id}` as `causes.${CauseId}`
                            )}
                            className="h-4 w-4 rounded border-gray-300 text-zinc-600 focus:ring-zinc-600"
                          />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label
                            htmlFor={cause.id}
                            className="font-medium text-gray-900"
                          >
                            {cause.name}
                          </label>{' '}
                          <span className="text-gray-500">
                            {cause.description}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-6">
          <div className="md:col-span-2">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Contact Information
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Provide contact details for your organisation.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-x-6 gap-y-8 md:col-span-4">
            <div className="col-span-full">
              <label
                htmlFor="contactName"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Contact person
              </label>
              <div className="mt-2">
                <Input
                  {...register('contactName')}
                  id="contactName"
                  className="w-full"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-x-6">
              <div>
                <label
                  htmlFor="contactEmail"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <Input
                    {...register('contactEmail')}
                    id="contactEmail"
                    type="email"
                    className="w-full"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="contactPhone"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Phone number
                </label>
                <div className="mt-2">
                  <Input
                    {...register('contactPhone')}
                    id="contactPhone"
                    type="tel"
                    className="w-full"
                  />
                </div>
              </div>
            </div>

            <div className="col-span-full">
              <label
                htmlFor="website"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Website
              </label>
              <div className="mt-2">
                <Input
                  {...register('website')}
                  id="website"
                  type="url"
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-6">
          <div className="md:col-span-2">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Address
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Provide your organisation&apos;s address details.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-x-6 gap-y-8 md:col-span-4">
            <div className="col-span-full">
              <label
                htmlFor="addressLine1"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Address line 1
              </label>
              <div className="mt-2">
                <Input
                  {...register('addressLine1')}
                  id="addressLine1"
                  className="w-full"
                />
              </div>
            </div>

            <div className="col-span-full">
              <label
                htmlFor="addressLine2"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Address line 2 (optional)
              </label>
              <div className="mt-2">
                <Input
                  {...register('addressLine2')}
                  id="addressLine2"
                  className="w-full"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-x-6">
              <div>
                <label
                  htmlFor="suburb"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Suburb
                </label>
                <div className="mt-2">
                  <Input
                    {...register('suburb')}
                    id="suburb"
                    className="w-full"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="postcode"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Postcode
                </label>
                <div className="mt-2">
                  <Input
                    {...register('postcode')}
                    id="postcode"
                    className="w-full"
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-x-6">
              <div>
                <label
                  htmlFor="state"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  State
                </label>
                <div className="mt-2">
                  <Input {...register('state')} id="state" className="w-full" />
                </div>
              </div>

              <div>
                <label
                  htmlFor="country"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Country
                </label>
                <div className="mt-2">
                  <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        id="country"
                        className="w-full"
                        value={field.value || ''}
                      >
                        <option value="">Select a country</option>
                        <option value="AU">Australia</option>
                      </Select>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-3">
          <Button type="button" plain onClick={() => reset()}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={isSaving}
            className="min-h-[38px] flex items-center justify-center"
          >
            {isSaving ? (
              <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            ) : (
              'Save'
            )}
          </Button>
        </div>
      </form>
      <EditDomainDialog
        isOpen={isEditDomainOpen}
        onClose={() => setIsEditDomainOpen(false)}
        onSave={handleSaveDomain}
        initialDomain={currentOrganisation?.domain || ''}
      />
    </>
  );
}
