import { Heading } from '../../components/common/catalyst/heading';
import { ComingSoon } from '../../components/common/ComingSoon';
import { ComingSoonPageContainer } from '../../components/layout/ComingSoonPageContainer';

export default function Technology() {
  return (
    <ComingSoonPageContainer>
      <Heading className="mb-2">Technology Overview</Heading>
      <ComingSoon
        title="Explore Your IT Landscape"
        description="Soon, you'll have a comprehensive view of all the tools and technologies in use within your organization."
      >
        <li>View a complete list of software and tools in use</li>
        <li>Identify redundancies and optimize tool usage</li>
        <li>Ensure compliance with licensing and regulations</li>
        <li>Facilitate better decision-making with data insights</li>
      </ComingSoon>
    </ComingSoonPageContainer>
  );
}
