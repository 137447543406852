import React from 'react';
import { useFormContext, get, RegisterOptions } from 'react-hook-form';
import {
  Fieldset,
  Field,
  Label,
  Description,
  ErrorMessage,
} from '../../../../common/catalyst/fieldset';
import { Input } from '../../../../common/catalyst/input';
import { OnboardingFormInputs } from '../../../../../types/OnboardingFormInputs';

type ValidFieldNames =
  | keyof OnboardingFormInputs
  | 'user.id'
  | 'user.firstName'
  | 'user.lastName'
  | 'organisation.id'
  | 'organisation.type'
  | 'organisation.name'
  | 'organisation.size'
  | 'userRole.id'
  | 'userRole.title'
  | 'userRole.team'
  | 'userRole.otherTeam';

interface GenericStepProps {
  title: string;
  description: string;
  fieldName: ValidFieldNames;
  placeholder: string;
  validation: RegisterOptions<OnboardingFormInputs, ValidFieldNames>;
  autoFocus?: boolean;
}

export const GenericStep: React.FC<GenericStepProps> = ({
  title,
  description,
  fieldName,
  placeholder,
  validation,
  autoFocus = true,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<OnboardingFormInputs>();

  return (
    <div className="flex items-start justify-start h-full w-full p-1">
      <Fieldset className="w-full max-w-md">
        <Field>
          <Label className="text-left text-2xl">{title}</Label>
          <Description className="text-left">{description}</Description>
          <Input
            {...register(fieldName, validation)}
            type="text"
            placeholder={placeholder}
            className="w-full mt-2"
            autoFocus={autoFocus}
          />
          {get(errors, fieldName) && (
            <ErrorMessage className="text-left">
              {get(errors, fieldName)?.message}
            </ErrorMessage>
          )}
        </Field>
      </Fieldset>
    </div>
  );
};
