import { Heading } from '../../components/common/catalyst/heading';
import { ComingSoon } from '../../components/common/ComingSoon';
import { ComingSoonPageContainer } from '../../components/layout/ComingSoonPageContainer';

export default function Wiki() {
  return (
    <ComingSoonPageContainer>
      <Heading className="mb-2">Wiki</Heading>
      <ComingSoon
        title="Introducing Wiki"
        description="Soon, you'll be able to create and manage comprehensive documentation that combines official guides with your organization's processes."
      >
        <li>Create living documentation that evolves with you</li>
        <li>Combine internal and external documentation</li>
        <li>Share standardized procedures across your team</li>
        <li>Access context-aware help and guidance</li>
      </ComingSoon>
    </ComingSoonPageContainer>
  );
}
