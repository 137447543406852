import { Navigate, Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { useCurrentUser } from '../../hooks/useCurrentUser';

export default function AuthRoute() {
  const { isAuthenticated, isLoading } = useCurrentUser();
  const location = useLocation();

  if (isLoading) {
    return null;
  } else {
    const allowedPaths = [
      '/reset-password',
      '/update-password',
      '/accept-invitation',
    ];
    if (!isAuthenticated || allowedPaths.includes(location.pathname)) {
      return (
        <div
          className="flex min-h-full flex-1 bg-pine-900"
          style={{
            backgroundImage: "url('/assets/img/z-pine-800.png')",
            backgroundSize: 'contain',
            backgroundPosition: 'right',
            height: '100vh',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96 bg-white p-8 rounded-2xl shadow-sm">
              <Outlet />
            </div>
          </div>
        </div>
      );
    } else {
      return <Navigate to="/" replace />;
    }
  }
}
