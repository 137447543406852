import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Fieldset,
  Field,
  Label,
  Description,
  ErrorMessage,
} from '../../../../common/catalyst/fieldset';
import { Radio, RadioGroup } from '@headlessui/react';
import { OnboardingFormInputs } from '../../../../../types/OnboardingFormInputs';

const colorMatrix = [
  [
    { name: 'Red', value: 'red-500', color: 'bg-red-500' },
    { name: 'Orange', value: 'orange-500', color: 'bg-orange-500' },
    { name: 'Yellow', value: 'yellow-500', color: 'bg-yellow-500' },
    { name: 'Green', value: 'green-500', color: 'bg-green-500' },
    { name: 'Teal', value: 'teal-500', color: 'bg-teal-500' },
    { name: 'Blue', value: 'blue-500', color: 'bg-blue-500' },
    { name: 'Indigo', value: 'indigo-500', color: 'bg-indigo-500' },
    { name: 'Violet', value: 'violet-500', color: 'bg-violet-500' },
  ],
  [
    { name: 'Dark Red', value: 'red-700', color: 'bg-red-700' },
    { name: 'Dark Orange', value: 'orange-700', color: 'bg-orange-700' },
    { name: 'Dark Yellow', value: 'yellow-700', color: 'bg-yellow-700' },
    { name: 'Dark Green', value: 'green-700', color: 'bg-green-700' },
    { name: 'Dark Teal', value: 'teal-700', color: 'bg-teal-700' },
    { name: 'Dark Blue', value: 'blue-700', color: 'bg-blue-700' },
    { name: 'Dark Indigo', value: 'indigo-700', color: 'bg-indigo-700' },
    { name: 'Dark Violet', value: 'violet-700', color: 'bg-violet-700' },
  ],
  [
    { name: 'Very Dark Red', value: 'red-900', color: 'bg-red-900' },
    { name: 'Very Dark Orange', value: 'orange-900', color: 'bg-orange-900' },
    { name: 'Very Dark Yellow', value: 'yellow-900', color: 'bg-yellow-900' },
    { name: 'Very Dark Green', value: 'green-900', color: 'bg-green-900' },
    { name: 'Very Dark Teal', value: 'teal-900', color: 'bg-teal-900' },
    { name: 'Very Dark Blue', value: 'blue-900', color: 'bg-blue-900' },
    { name: 'Very Dark Indigo', value: 'indigo-900', color: 'bg-indigo-900' },
    { name: 'Very Dark Violet', value: 'violet-900', color: 'bg-violet-900' },
  ],
];

export const OrganisationColourStep: React.FC = () => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<OnboardingFormInputs>();
  const selectedColor = watch('organisation.colour');

  return (
    <div className="flex items-start justify-start h-full w-full p-1">
      <Fieldset className="w-full max-w-md">
        <Field>
          <Label className="text-left text-2xl">Organisation colour</Label>
          <Description className="text-left">
            Choose a colour for your organisation&apos;s theme.
          </Description>
          <RadioGroup
            value={selectedColor}
            onChange={(value) =>
              setValue('organisation.colour', value, { shouldValidate: true })
            }
            className="mt-8 flex flex-wrap gap-3"
          >
            {colorMatrix.map((column, columnIndex) => (
              <div key={columnIndex} className="flex flex-col space-y-3">
                {column.map((option) => (
                  <Radio
                    key={option.value}
                    value={option.value}
                    className={`${option.color} w-8 h-8 rounded-full cursor-pointer focus:outline-none ring-offset-2`}
                  >
                    {({ checked }) => (
                      <span
                        className={`block w-full h-full rounded-full ${checked ? 'ring-2 ring-zinc-600 ring-offset-2' : ''}`}
                      >
                        <span className="sr-only">{option.name}</span>
                      </span>
                    )}
                  </Radio>
                ))}
              </div>
            ))}
          </RadioGroup>
          <input
            type="hidden"
            {...register('organisation.colour', {
              required: 'Please select a colour',
            })}
          />
          {errors.organisation?.colour && (
            <ErrorMessage className="text-left">
              {errors.organisation.colour.message}
            </ErrorMessage>
          )}
        </Field>
      </Fieldset>
    </div>
  );
};
