import { Heading } from '../../components/common/catalyst/heading';
import { ComingSoon } from '../../components/common/ComingSoon';
import { ComingSoonPageContainer } from '../../components/layout/ComingSoonPageContainer';

export default function Password() {
  return (
    <ComingSoonPageContainer>
      <Heading className="mb-2">Password Management</Heading>
      <ComingSoon
        title="Introducing Password Management"
        description="Soon, you'll be able to simply and securely manage your organisation's passwords on our platform."
      >
        <li>Share passwords securely with team members</li>
        <li>Centralize all your organization&apos;s credentials</li>
        <li>Receive password rotation reminders</li>
      </ComingSoon>
    </ComingSoonPageContainer>
  );
}
