import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  fetchOrganisation,
  updateOrganisation,
} from '../services/organisationService';
import { Organisation } from '../types';

export function useOrganisation(organisationId: string) {
  const queryClient = useQueryClient();

  const {
    data: organisation,
    isLoading,
    isError,
  } = useQuery<Organisation>({
    queryKey: ['organisation', organisationId],
    queryFn: () => fetchOrganisation(organisationId),
    enabled: !!organisationId,
  });

  const updateOrganisationMutation = useMutation<
    Organisation,
    Error,
    { organisationId: string; updates: Partial<Organisation> }
  >({
    mutationFn: ({ organisationId, updates }) =>
      updateOrganisation(organisationId, updates),
    onSuccess: (updatedOrganisation) => {
      queryClient.setQueryData(
        ['organisation', organisationId],
        updatedOrganisation
      );
      queryClient.invalidateQueries({
        queryKey: ['organisation', organisationId],
        refetchType: 'all',
      });
    },
  });

  return {
    organisation,
    isLoading,
    isError,
    updateOrganisation: updateOrganisationMutation.mutateAsync,
  };
}
