import { Organisation } from '../types/Organisation';

export const fetchOrganisation = async (
  organisationId: string
): Promise<Organisation> => {
  const response = await fetch(`/api/organisation/${organisationId}`, {
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error('Failed to fetch organisation');
  }
  return response.json();
};

export const updateOrganisation = async (
  organisationId: string,
  updates: Partial<Organisation>
): Promise<Organisation> => {
  const response = await fetch(`/api/organisation/${organisationId}`, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(updates),
    credentials: 'include',
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to update organisation');
  }
  return response.json();
};

export const fetchUserOrganisations = async (
  userId: string
): Promise<Organisation[]> => {
  const response = await fetch(`/api/user/${userId}/organisations`, {
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error('Failed to fetch user organisations');
  }
  return response.json();
};
