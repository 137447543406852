import { QuestionMarkCircleIcon } from '@heroicons/react/16/solid';
import {
  UseFormRegister,
  RegisterOptions,
  FieldValues,
  Path,
} from 'react-hook-form';

interface InputFieldProps<TFieldValues extends FieldValues> {
  label: string;
  id: string;
  name: Path<TFieldValues>;
  type: string;
  placeholder?: string;
  autofill?: string;
  register: UseFormRegister<TFieldValues>;
  validationSchema?: RegisterOptions<TFieldValues, Path<TFieldValues>>;
  errors?: {
    message?: string;
  };
  disabled?: boolean;
  readOnly?: boolean;
  value?: string;
  helpText?: string;
  autoFocus?: boolean;
  onBlur?: () => void;
}

export function InputField<TFieldValues extends FieldValues>({
  label,
  id,
  name,
  type,
  placeholder,
  autofill,
  register,
  validationSchema,
  errors,
  disabled,
  readOnly,
  value,
  helpText,
  autoFocus,
  onBlur,
}: InputFieldProps<TFieldValues>) {
  return (
    <div>
      <div className="flex items-center space-x-1">
        <label
          htmlFor={id}
          className="block text-sm font-medium leading-6 text-pine-800"
        >
          {label}
        </label>
        {helpText && (
          <div className="relative group">
            <QuestionMarkCircleIcon className="h-4 w-4 text-pine-800 hover:text-pine-700 cursor-help" />
            <div className="absolute z-10 invisible group-hover:visible bg-white border border-graphite-200 rounded-md p-2 text-sm text-pine-800 w-64 bottom-full left-1/2 transform -translate-x-1/2 mb-2 shadow-lg">
              {helpText}
            </div>
          </div>
        )}
      </div>
      <div className="mt-1">
        <input
          id={id}
          type={type}
          placeholder={placeholder}
          autoComplete={autofill}
          {...register(name, validationSchema)}
          disabled={disabled}
          readOnly={readOnly}
          autoFocus={autoFocus}
          onBlur={onBlur}
          className={`block w-full rounded-xl border-0 py-2 px-3 ring-1 ring-graphite-200 placeholder:text-graphite-400 hover:bg-graphite-25 focus:bg-graphite-25 focus:ring-2 focus:ring-inset focus:ring-pine-600 sm:text-sm sm:leading-6 ${
            disabled
              ? 'bg-graphite-100 text-graphite-500 cursor-not-allowed'
              : ''
          }`}
          value={value}
        />
      </div>
      <p className="mt-2 text-xs leading-4 text-coral-700">
        {errors && errors.message}
      </p>
    </div>
  );
}
