import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  getUsers,
  addUserApi,
  updateUserApi,
  deleteUserApi,
  reactivateUserApi,
  makeAccountOwnerApi,
  updateUserEmailApi,
  resendInvitationApi,
  deleteUsersApi,
} from '../services/userService';
import {
  OrganisationUser,
  AddUserFormInputs,
  UpdateEmailFormInputs,
  EditUserFormInputs,
} from '../types';

export function useUsers(organisationId: string) {
  const queryClient = useQueryClient();

  const {
    data: users = [],
    isLoading,
    isError,
  } = useQuery<OrganisationUser[]>({
    queryKey: ['users', organisationId],
    queryFn: () => getUsers(organisationId),
    enabled: !!organisationId,
  });

  const addUserMutation = useMutation({
    mutationFn: (data: AddUserFormInputs) => addUserApi(organisationId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users', organisationId],
        refetchType: 'all',
      });
    },
  });

  const updateUserMutation = useMutation({
    mutationFn: (data: EditUserFormInputs) =>
      updateUserApi(organisationId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users', organisationId],
        refetchType: 'all',
      });
    },
  });

  const deleteUserMutation = useMutation({
    mutationFn: (userId: string) => deleteUserApi(userId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users', organisationId],
        refetchType: 'all',
      });
    },
  });

  const reactivateUserMutation = useMutation({
    mutationFn: (userId: string) => reactivateUserApi(organisationId, userId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users', organisationId],
        refetchType: 'all',
      });
    },
  });

  const makeAccountOwnerMutation = useMutation({
    mutationFn: (userId: string) => makeAccountOwnerApi(organisationId, userId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users', organisationId],
        refetchType: 'all',
      });
    },
  });

  const updateUserEmailMutation = useMutation({
    mutationFn: (data: UpdateEmailFormInputs) =>
      updateUserEmailApi(organisationId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users', organisationId],
        refetchType: 'all',
      });
    },
  });

  const resendInvitationMutation = useMutation({
    mutationFn: (userId: string) => resendInvitationApi(organisationId, userId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users', organisationId],
        refetchType: 'all',
      });
    },
  });

  const deleteUsersMutation = useMutation({
    mutationFn: (userIds: string[]) => deleteUsersApi(organisationId, userIds),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['users', organisationId],
        refetchType: 'all',
      });
    },
  });

  const addUser = async (data: AddUserFormInputs) => {
    await addUserMutation.mutateAsync(data);
  };

  const updateUser = async (data: EditUserFormInputs) => {
    await updateUserMutation.mutateAsync(data);
  };

  const deleteUser = async (userId: string) => {
    await deleteUserMutation.mutateAsync(userId);
  };

  const reactivateUser = async (userId: string) => {
    await reactivateUserMutation.mutateAsync(userId);
  };

  const makeAccountOwner = async (userId: string) => {
    await makeAccountOwnerMutation.mutateAsync(userId);
  };

  const updateUserEmail = async (data: UpdateEmailFormInputs) => {
    await updateUserEmailMutation.mutateAsync(data);
  };

  const resendInvitation = async (userId: string) => {
    await resendInvitationMutation.mutateAsync(userId);
  };

  const deleteUsers = async (userIds: string[]) => {
    await deleteUsersMutation.mutateAsync(userIds);
  };

  return {
    users,
    addUser,
    updateUser,
    deleteUser,
    reactivateUser,
    makeAccountOwner,
    updateUserEmail,
    resendInvitation,
    deleteUsers,
    isLoading,
    isError,
  };
}
