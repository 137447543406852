import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Fieldset,
  Field,
  Label,
  Description,
  ErrorMessage,
} from '../../../../common/catalyst/fieldset';
import { Input } from '../../../../common/catalyst/input';
import { Select } from '../../../../common/catalyst/select';
import { OnboardingFormInputs } from '../../../../../types/OnboardingFormInputs';

const teamOptions = [
  'Board',
  'Executive Leadership',
  'Operations',
  'Programs',
  'Marketing',
  'Fundraising',
  'Finance',
  'Information Technology',
  'Human Resources',
  'Volunteers',
  'Other',
];

export const TeamStep: React.FC = () => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<OnboardingFormInputs>();
  const selectedTeam = watch('userRole.team');

  const handleTeamChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue('userRole.team', e.target.value);
    if (e.target.value !== 'Other') {
      setValue('userRole.otherTeam', '');
    }
  };

  return (
    <div className="flex items-start justify-start h-full w-full p-1">
      <Fieldset className="w-full max-w-md">
        <Field>
          <Label className="text-left text-2xl">Your team</Label>
          <Description className="text-left">
            Please select your team.
          </Description>
          <Select
            {...register('userRole.team', { required: 'Team is required' })}
            className="w-full mt-2"
            onChange={handleTeamChange}
          >
            <option value="">Select a team</option>
            {teamOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </Select>
          {selectedTeam === 'Other' && (
            <Input
              {...register('userRole.otherTeam', {
                required: 'Please specify your team',
                setValueAs: (v: string) =>
                  v.replace(
                    /\w\S*/g,
                    (txt) =>
                      txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
                  ),
              })}
              type="text"
              placeholder="Please specify your team"
              className="w-full mt-2"
            />
          )}
          {errors.userRole?.team && (
            <ErrorMessage className="text-left">
              {errors.userRole.team.message}
            </ErrorMessage>
          )}
          {errors.userRole?.otherTeam && (
            <ErrorMessage className="text-left">
              {errors.userRole.otherTeam.message}
            </ErrorMessage>
          )}
        </Field>
      </Fieldset>
    </div>
  );
};
