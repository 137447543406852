import { useRef } from 'react';
import ms from 'ms';

export const useTokenRefresh = () => {
  const serverTokenInterval = ms(
    (import.meta.env.VITE_TOKEN_EXPIRATION as string) || '15m'
  );
  const clientTokenInterval = serverTokenInterval - ms('1m');

  const refreshTokenTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );

  const scheduleTokenRefresh = () => {
    console.log(`Next refresh scheduled in ${clientTokenInterval}ms`);

    if (refreshTokenTimeoutRef.current) {
      clearTimeout(refreshTokenTimeoutRef.current);
    }

    refreshTokenTimeoutRef.current = setTimeout(
      refreshToken,
      clientTokenInterval
    );
  };

  const clearTokenRefresh = () => {
    if (refreshTokenTimeoutRef.current) {
      clearTimeout(refreshTokenTimeoutRef.current);
    }
  };

  const refreshToken = async () => {
    try {
      const response = await fetch('/api/auth/refresh-token', {
        method: 'POST',
        credentials: 'include',
      });
      if (response.ok) {
        console.log('Token refreshed successfully');
        scheduleTokenRefresh();
      } else {
        console.log('Failed to refresh token');
      }
    } catch (error) {
      console.error('Failed to refresh token:', error);
    }
  };

  return {
    serverTokenInterval,
    clientTokenInterval,
    scheduleTokenRefresh,
    clearTokenRefresh,
  };
};
