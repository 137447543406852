import { Heading } from '../../components/common/catalyst/heading';
import { ComingSoon } from '../../components/common/ComingSoon';
import { ComingSoonPageContainer } from '../../components/layout/ComingSoonPageContainer';

export default function Surveying() {
  return (
    <ComingSoonPageContainer>
      <Heading className="mb-2">Surveying</Heading>
      <ComingSoon
        title="Gather Insights with Surveys"
        description="Soon, you'll be able to collect valuable feedback and insights through surveys, helping you make informed decisions."
      >
        <li>Create and distribute surveys to gather feedback</li>
        <li>Analyze survey results to understand user sentiment</li>
        <li>Make data-driven decisions to improve IT services</li>
        <li>Engage with staff and stakeholders effectively</li>
      </ComingSoon>
    </ComingSoonPageContainer>
  );
}
