import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface UserRoleStore {
  currentUserRole: number;
  setCurrentUserRole: (userRole: number) => void;
}

export const useCurrentUserRoleStore = create<UserRoleStore>()(
  persist(
    (set) => ({
      currentUserRole: 0,
      setCurrentUserRole: (userRole) => set({ currentUserRole: userRole }),
    }),
    {
      name: 'user-role-storage',
    }
  )
);
