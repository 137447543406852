import { useQuery } from '@tanstack/react-query';
import { useCurrentUser } from './useCurrentUser';
import { fetchCurrentUserRoles } from '../services/userRoleService';
import { UserRole } from '../types';

export function useCurrentUserRoles() {
  const { isAuthenticated } = useCurrentUser();

  const {
    data: userRoles,
    isLoading,
    isError,
    refetch: originalRefetch,
  } = useQuery<UserRole[]>({
    queryKey: ['currentUserRoles'],
    queryFn: fetchCurrentUserRoles,
    enabled: isAuthenticated,
  });

  // Wrap refetch to make it easier to debug
  const refetch = async () => {
    console.log('Starting refetch in useCurrentUserRoles');
    try {
      const result = await originalRefetch();
      console.log('Refetch completed, new data:', result.data);
      return result;
    } catch (error) {
      console.error('Error during refetch:', error);
      throw error;
    }
  };

  return {
    userRoles,
    isLoading,
    isError,
    refetch,
  };
}
