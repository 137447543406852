import React from 'react';
import { GenericStep } from './GenericStep';

export const TitleStep: React.FC = () => (
  <GenericStep
    title="Your job title"
    description="Please enter your job title."
    fieldName="userRole.title"
    placeholder="e.g. Marketing Manager"
    validation={{
      required: 'Job title is required',
      minLength: {
        value: 2,
        message: 'Job title must contain at least two characters',
      },
    }}
  />
);
