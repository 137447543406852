import { useEffect } from 'react';
import { useCurrentUserRoles } from './useCurrentUserRoles';
import { useCurrentUserRoleStore } from '../stores/currentUserRoleStore';

export function useCurrentUserRole() {
  const {
    userRoles: currentUserRoles,
    isLoading,
    isError,
  } = useCurrentUserRoles();
  const currentRoleIndex = useCurrentUserRoleStore(
    (state) => state.currentUserRole
  );
  const switchRole = useCurrentUserRoleStore(
    (state) => state.setCurrentUserRole
  );

  // Validate the currentRoleIndex whenever currentUserRoles change
  useEffect(() => {
    if (!isLoading && currentUserRoles) {
      if (currentUserRoles.length === 0) {
        // User has no organisations
        // You might want to redirect them or show a message
        switchRole(-1); // or any other logic
      } else if (
        currentRoleIndex < 0 ||
        currentRoleIndex >= currentUserRoles.length ||
        !currentUserRoles[currentRoleIndex]
      ) {
        // Current index is invalid, default to 0
        switchRole(0);
      }
    }
  }, [currentUserRoles, isLoading, currentRoleIndex, switchRole]);

  const currentRole =
    currentUserRoles && currentUserRoles[currentRoleIndex]
      ? currentUserRoles[currentRoleIndex]
      : null;

  return {
    currentRole,
    switchRole,
    isLoading,
    isError,
  };
}
