import React from 'react';
import { useFormContext, Path, get } from 'react-hook-form';
import {
  Fieldset,
  Field,
  Label,
  Description,
  ErrorMessage,
} from '../../../../common/catalyst/fieldset';
import { OnboardingFormInputs } from '../../../../../types/OnboardingFormInputs';

const causes = [
  {
    id: 'animal_welfare',
    name: 'Animal welfare',
    description: 'Promote animal welfare and rights',
  },
  {
    id: 'arts_and_culture',
    name: 'Arts and culture',
    description: 'Support and preserve artistic and cultural expressions',
  },
  {
    id: 'children_and_youth',
    name: 'Children and youth',
    description: 'Improve the lives and opportunities of young people',
  },
  {
    id: 'community_development',
    name: 'Community development',
    description: 'Strengthen and improve local communities',
  },
  {
    id: 'disability_services',
    name: 'Disability services',
    description: 'Support inclusion and accessibility',
  },
  {
    id: 'disaster_relief',
    name: 'Disaster relief',
    description:
      'Provide assistance in the aftermath of natural or humanitarian disasters',
  },
  {
    id: 'education_and_literacy',
    name: 'Education and literacy',
    description: 'Promote access to education for all',
  },
  {
    id: 'elderly_care',
    name: 'Elderly care',
    description: 'Improve the quality of life for seniors',
  },
  {
    id: 'environment_and_conservation',
    name: 'Environment and conservation',
    description: 'Protect nature and combat climate change',
  },
  {
    id: 'food_security',
    name: 'Food security and hunger relief',
    description: 'Fight hunger and promote access to healthy food',
  },
  {
    id: 'health_and_medical_research',
    name: 'Health and medical research',
    description: 'Improve public health and advance medical research',
  },
  {
    id: 'homelessness_and_housing',
    name: 'Homelessness and housing',
    description: 'Provide shelter and sustainable housing solutions',
  },
  {
    id: 'international_development',
    name: 'International development',
    description: 'Promote social and economic progress worldwide',
  },
  {
    id: 'lgbtqia_rights',
    name: 'LGBTQIA+ rights and advocacy',
    description: 'Defend equality and inclusion for the LGBTQIA+ community',
  },
  {
    id: 'mental_health',
    name: 'Mental health and wellbeing',
    description: 'Support mental health and combat stigma',
  },
  {
    id: 'poverty_alleviation',
    name: 'Poverty alleviation',
    description: 'Reduce economic and social inequalities',
  },
  {
    id: 'racial_equality',
    name: 'Racial and ethnic equality',
    description: 'Combat racism and promote equity',
  },
  {
    id: 'refugees_and_immigration',
    name: 'Refugees and immigration',
    description: 'Support the rights and integration of migrants and refugees',
  },
  {
    id: 'religion_and_spirituality',
    name: 'Religion and spirituality',
    description: 'Promote interfaith understanding and respect',
  },
  {
    id: 'sports_and_recreation',
    name: 'Sports and recreation',
    description: 'Encourage physical activity and wellbeing through sports',
  },
  {
    id: 'veterans_and_military',
    name: 'Veterans and military families',
    description: 'Support those who have served and their families',
  },
  {
    id: 'womens_empowerment',
    name: "Women's empowerment and gender equality",
    description: "Promote gender equality and women's rights",
  },
];

export const OrganisationCausesStep: React.FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<OnboardingFormInputs>();

  return (
    <div className="flex items-start justify-start h-full w-full p-0">
      <Fieldset className="w-full">
        <Field>
          <Label className="text-left text-2xl pl-1">Causes</Label>
          <Description className="text-left pl-1">
            Select the causes your organisation supports.
          </Description>
          <div className="mt-4 max-h-[60vh] overflow-y-auto pr-4 pl-1">
            <div className="space-y-5">
              {causes.map((cause) => (
                <div key={cause.id} className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id={cause.id}
                      {...register(
                        `organisation.causes.${cause.id}` as Path<OnboardingFormInputs>
                      )}
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-zinc-600 focus:ring-zinc-600"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor={cause.id}
                      className="font-medium text-gray-900"
                    >
                      {cause.name}
                    </label>{' '}
                    <span className="text-gray-500">{cause.description}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {get(errors, 'organisation.causes') && (
            <ErrorMessage className="text-left">
              {get(errors, 'organisation.causes')?.message}
            </ErrorMessage>
          )}
        </Field>
      </Fieldset>
    </div>
  );
};
