import { Heading } from '../../components/common/catalyst/heading';
import { ComingSoon } from '../../components/common/ComingSoon';
import { ComingSoonPageContainer } from '../../components/layout/ComingSoonPageContainer';

export default function Dashboard() {
  return (
    <ComingSoonPageContainer>
      <Heading className="mb-2">Dashboard</Heading>
      <ComingSoon
        title="Your IT Management Dashboard"
        description="Soon, you'll have a comprehensive overview of your organization's IT environment at your fingertips."
      >
        <li>Monitor key performance indicators in real-time</li>
        <li>Access critical alerts and notifications</li>
        <li>Visualize data with interactive charts and graphs</li>
        <li>Customize your dashboard to fit your needs</li>
      </ComingSoon>
    </ComingSoonPageContainer>
  );
}
