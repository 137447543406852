import { useEffect } from 'react';
import { useCurrentUser } from '../../hooks/useCurrentUser';

export default function LogOut() {
  const { logout } = useCurrentUser();

  useEffect(() => {
    logout();
  }, [logout]);

  return <div>Redirecting to login...</div>;
}
