import { Outlet } from 'react-router-dom';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { useCurrentUserRole } from '../../../hooks/useCurrentUserRole';
import { useUserPermissions } from '../../../hooks/useUserPermissions';
import { useCurrentOrganisation } from '../../../hooks/useCurrentOrganisation';
import SpinnerComponent from '../../common/SpinnerComponent';
import { OnboardingModal } from '../dialogs/onboarding/OnboardingModal';

export default function RequiresOnboarding() {
  const { user, isLoading: loadingUser } = useCurrentUser();
  const { currentRole, isLoading: loadingUserRole } = useCurrentUserRole();
  const { hasPermission } = useUserPermissions();
  const { currentOrganisation, isLoading: loadingOrganisation } =
    useCurrentOrganisation();

  const isLoading = loadingUser || loadingUserRole || loadingOrganisation;

  if (isLoading) {
    return (
      <div className="fixed inset-0 z-50 bg-white">
        <SpinnerComponent />
      </div>
    );
  }

  if (!user || !currentRole || !currentOrganisation) {
    return null;
  }

  const isUserComplete = () => {
    return user && user.firstName && user.lastName;
  };

  const isOrganisationComplete = () => {
    return (
      currentOrganisation &&
      currentOrganisation.name &&
      currentOrganisation.type &&
      currentOrganisation.size &&
      currentOrganisation.colour
    );
  };

  const isUserRoleComplete = () => {
    return currentRole && currentRole.title && currentRole.team;
  };

  const handleComplete = () => {
    console.log('requires onboarding got complete');
    console.log(user);
    console.log(currentRole);
    console.log(currentOrganisation);
    // navigate('/')
  };

  if (
    isUserComplete() &&
    (hasPermission('admin') ? isOrganisationComplete() : true) &&
    isUserRoleComplete()
  ) {
    return <Outlet />;
  } else {
    return (
      <div className="fixed inset-0 z-50 bg-white">
        <OnboardingModal
          user={user}
          currentRole={currentRole}
          currentOrganisation={currentOrganisation}
          onComplete={handleComplete}
        />
      </div>
    );
  }
}
