import { create } from 'zustand';

interface ToastState {
  showToast: boolean;
  message: string;
  type: 'success' | 'error';
  show: (message: string, type: 'success' | 'error', duration?: number) => void;
  hide: () => void;
}

export const useToastStore = create<ToastState>((set) => ({
  showToast: false,
  message: '',
  type: 'success',
  show: (message, type, duration = 3000) => {
    set({ showToast: true, message, type });
    setTimeout(
      () => set({ showToast: false, message: '', type: 'success' }),
      duration
    );
  },
  hide: () => set({ showToast: false, message: '', type: 'success' }),
}));
