import { useLocation } from 'react-router-dom';
import { Avatar } from '../common/catalyst/avatar';
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from '../common/catalyst/dropdown';
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
} from '../common/catalyst/sidebar';
import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Cog6ToothIcon,
} from '@heroicons/react/16/solid';
import {
  BellIcon,
  ChartPieIcon,
  ComputerDesktopIcon,
  HomeIcon,
  KeyIcon,
  BookOpenIcon,
  ChartBarIcon,
  InboxIcon,
  MapIcon,
  UserGroupIcon,
} from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useCurrentUserRole } from '../../hooks/useCurrentUserRole';
import { useCurrentOrganisation } from '../../hooks/useCurrentOrganisation';
import { useUserPermissions } from '../../hooks/useUserPermissions';
import { useCurrentUserOrganisations } from '../../hooks/useCurrentUserOrganisations';
import { themeBackgroundColour } from '../../utils/organisationTheme';

export function PrimaryNavigation() {
  const navigate = useNavigate();
  const location = useLocation();

  const { user, logout } = useCurrentUser();
  const { switchRole } = useCurrentUserRole();
  const { currentOrganisation } = useCurrentOrganisation();

  const { hasPermission } = useUserPermissions();

  const { organisations } = useCurrentUserOrganisations();

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  const handleLogout = async () => {
    await logout();
  };

  const getInitial = (name: string) => {
    if (name && name.length) {
      return name[0].toUpperCase();
    } else {
      return '?';
    }
  };

  const getInitials = (firstName: string, lastName: string) => {
    return (firstName[0] + lastName[0]).toUpperCase();
  };

  const isCurrentRoute = (path: string) => {
    return location.pathname === path;
  };

  const isAdminOrGreater = hasPermission('admin');

  return (
    <Sidebar>
      <SidebarHeader>
        <Dropdown>
          <DropdownButton as={SidebarItem} className="mb-2.5">
            <Avatar
              initials={getInitial(
                currentOrganisation?.name || currentOrganisation?.domain || ''
              )}
              className={`${themeBackgroundColour(currentOrganisation?.colour)} text-white`}
            />
            <SidebarLabel>
              {currentOrganisation?.name ||
                currentOrganisation?.domain ||
                'No Organisation'}
            </SidebarLabel>
            <ChevronDownIcon />
          </DropdownButton>
          <DropdownMenu className="min-w-64" anchor="bottom start">
            {organisations &&
              organisations.map((organisation, index) => {
                return (
                  <DropdownItem key={index} onClick={() => switchRole(index)}>
                    <Avatar
                      slot="icon"
                      initials={getInitial(
                        organisation?.name || organisation?.domain || ''
                      )}
                      className={`${themeBackgroundColour(organisation?.colour)} text-white`}
                    />
                    <DropdownLabel>
                      {organisation?.name || organisation?.domain}
                    </DropdownLabel>
                  </DropdownItem>
                );
              })}
            {isAdminOrGreater && (
              <>
                <DropdownDivider />
                <DropdownItem onClick={() => handleNavigation('/settings')}>
                  <Cog6ToothIcon />
                  <DropdownLabel>Settings</DropdownLabel>
                </DropdownItem>
              </>
            )}
          </DropdownMenu>
        </Dropdown>
        <SidebarSection>
          {/* <SidebarItem onClick={() => handleNavigation('/search')} current={isCurrentRoute('/search')}>
            <MagnifyingGlassIcon />
            <SidebarLabel>Search</SidebarLabel>
          </SidebarItem> */}
          <SidebarItem
            onClick={() => handleNavigation('/notifications')}
            current={isCurrentRoute('/notifications')}
          >
            <BellIcon />
            <SidebarLabel>Notifications</SidebarLabel>
          </SidebarItem>
        </SidebarSection>
      </SidebarHeader>
      <SidebarBody>
        <SidebarSection>
          {isAdminOrGreater && <SidebarHeading>General</SidebarHeading>}
          <SidebarItem
            onClick={() => handleNavigation('/')}
            current={isCurrentRoute('/')}
          >
            <HomeIcon />
            <SidebarLabel>Home</SidebarLabel>
          </SidebarItem>
          <SidebarItem
            onClick={() => handleNavigation('/wiki')}
            current={isCurrentRoute('/wiki')}
          >
            <BookOpenIcon />
            <SidebarLabel>Wiki</SidebarLabel>
          </SidebarItem>
          <SidebarItem
            onClick={() => handleNavigation('/passwords')}
            current={isCurrentRoute('/passwords')}
          >
            <KeyIcon />
            <SidebarLabel>Passwords</SidebarLabel>
          </SidebarItem>
        </SidebarSection>
        {isAdminOrGreater && (
          <SidebarSection>
            <SidebarHeading>Management</SidebarHeading>
            <SidebarItem
              onClick={() => handleNavigation('/dashboard')}
              current={isCurrentRoute('/dashboard')}
            >
              <ChartBarIcon />
              <SidebarLabel>Dashboard</SidebarLabel>
            </SidebarItem>
            <SidebarItem
              onClick={() => handleNavigation('/staff')}
              current={isCurrentRoute('/staff')}
            >
              <UserGroupIcon />
              <SidebarLabel>Staff</SidebarLabel>
            </SidebarItem>
            <SidebarItem
              onClick={() => handleNavigation('/technology')}
              current={isCurrentRoute('/technology')}
            >
              <ComputerDesktopIcon />
              <SidebarLabel>Technology</SidebarLabel>
            </SidebarItem>
            <SidebarItem
              onClick={() => handleNavigation('/support')}
              current={isCurrentRoute('/support')}
            >
              <InboxIcon />
              <SidebarLabel>Support</SidebarLabel>
            </SidebarItem>
          </SidebarSection>
        )}
        {isAdminOrGreater && (
          <SidebarSection>
            <SidebarHeading>Strategy</SidebarHeading>
            <SidebarItem
              onClick={() => handleNavigation('/surveying')}
              current={isCurrentRoute('/strategy')}
            >
              <ChartPieIcon />
              <SidebarLabel>Surveying</SidebarLabel>
            </SidebarItem>
            <SidebarItem
              onClick={() => handleNavigation('/architecture')}
              current={isCurrentRoute('/architecture')}
            >
              <MapIcon />
              <SidebarLabel>Architecture</SidebarLabel>
            </SidebarItem>
            <SidebarItem
              onClick={() => handleNavigation('/building')}
              current={isCurrentRoute('/building')}
            >
              <ComputerDesktopIcon />
              <SidebarLabel>Building</SidebarLabel>
            </SidebarItem>
          </SidebarSection>
        )}
        {/* <SidebarSpacer /> */}
      </SidebarBody>
      <SidebarFooter>
        <Dropdown>
          <DropdownButton as={SidebarItem}>
            <span className="flex min-w-0 items-center gap-3">
              <Avatar
                initials={
                  user?.firstName && user?.lastName
                    ? getInitials(user.firstName, user.lastName)
                    : getInitials(user?.email || '', '')
                }
                className={`${themeBackgroundColour(currentOrganisation?.colour)} text-white size-10`}
                square
                alt=""
              />
              <span className="min-w-0">
                <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                  {user?.firstName && user?.lastName
                    ? `${user.firstName} ${user.lastName}`
                    : 'Unknown User'}
                </span>
                <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                  {user?.email || 'email@exemple.com'}
                </span>
              </span>
            </span>
            <ChevronUpIcon />
          </DropdownButton>
          <DropdownMenu className="min-w-64" anchor="top start">
            {/* <DropdownItem onClick={() => handleNavigation('/my-profile')}>
              <UserIcon />
              <DropdownLabel>My profile</DropdownLabel>
            </DropdownItem>
            <DropdownItem onClick={() => handleNavigation('/settings')}>
              <Cog6ToothIcon />
              <DropdownLabel>Settings</DropdownLabel>
            </DropdownItem>
            <DropdownDivider /> */}
            {/* <DropdownItem onClick={() => handleNavigation('/help')}>
              <LifebuoyIcon />
              <DropdownLabel>Get support</DropdownLabel>
            </DropdownItem>
            <DropdownItem onClick={() => handleNavigation('/feedback')}>
              <LightBulbIcon />
              <DropdownLabel>Share feedback</DropdownLabel>
            </DropdownItem>
            <DropdownItem onClick={() => handleNavigation('/changelog')}>
              <SparklesIcon />
              <DropdownLabel>Updates</DropdownLabel>
            </DropdownItem>
            <DropdownItem onClick={() => handleNavigation('/privacy-policy')}>
              <ShieldCheckIcon />
              <DropdownLabel>Privacy policy</DropdownLabel>
            </DropdownItem>
            <DropdownDivider /> */}
            <DropdownItem onClick={handleLogout}>
              <ArrowRightStartOnRectangleIcon />
              <DropdownLabel>Log out</DropdownLabel>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </SidebarFooter>
    </Sidebar>
  );
}
