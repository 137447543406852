import { Heading } from '../../components/common/catalyst/heading';
import { ComingSoon } from '../../components/common/ComingSoon';
import { ComingSoonPageContainer } from '../../components/layout/ComingSoonPageContainer';

export default function Building() {
  return (
    <ComingSoonPageContainer>
      <Heading className="mb-2">Building</Heading>
      <ComingSoon
        title="Manage Your Projects Efficiently"
        description="Soon, you'll be able to track and manage in-progress projects with IT providers, consultants, and freelancers."
      >
        <li>Monitor project timelines and milestones</li>
        <li>Collaborate with IT providers and consultants</li>
        <li>Track progress and deliverables in real-time</li>
        <li>Ensure projects stay on schedule and within budget</li>
      </ComingSoon>
    </ComingSoonPageContainer>
  );
}
