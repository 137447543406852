import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { useUserPermissions } from '../../../hooks/useUserPermissions';

interface ProtectedRouteProps {
  requiredPermission?: string;
}

const ProtectedRoute = ({ requiredPermission }: ProtectedRouteProps) => {
  const { isAuthenticated, isLoading } = useCurrentUser();
  const { hasPermission } = useUserPermissions();
  const location = useLocation();

  if (isLoading) {
    return null;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (requiredPermission && !hasPermission(requiredPermission)) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
