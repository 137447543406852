import React from 'react';
import { Transition } from '@headlessui/react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useToastStore } from '../../stores/toastStore';

export const Toast: React.FC = () => {
  const { showToast, message, type, hide } = useToastStore();

  const icon =
    type === 'success' ? (
      <CheckIcon className="h-6 w-6 text-pine-600" aria-hidden="true" />
    ) : (
      <XMarkIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
    );

  return (
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-x-0 bottom-0 flex items-center justify-center px-4 py-10"
    >
      <div className="flex w-full max-w-md justify-center">
        <Transition
          show={showToast}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0"
          enterTo="translate-y-0 opacity-100"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="pointer-events-auto w-full overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-4 py-3">
              <div className="flex items-center">
                <div className="flex-shrink-0">{icon}</div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-gray-900">{message}</p>
                </div>
                <div className="ml-4 flex-shrink-0">
                  <button
                    type="button"
                    className="p-1 hover:bg-gray-100 rounded-full bg-white text-gray-400 hover:text-gray-500"
                    onClick={hide}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default Toast;
