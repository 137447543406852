import { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { useForm } from 'react-hook-form';

import { InputField } from '../../components/common/InputField.tsx';
import { Button } from '../../components/common/Button.tsx';

import { hashPassword } from '../../services/authService.ts';

import { ChevronLeftIcon } from '@heroicons/react/20/solid';

interface PasswordUpdateFormInputs {
  password: string;
  confirmPassword: string;
}

export default function PasswordUpdate() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');

  const form = useForm<PasswordUpdateFormInputs>();
  const { register, handleSubmit, formState, watch, reset } = form;
  const { errors } = formState;

  const onSubmit = async (data: PasswordUpdateFormInputs) => {
    setLoading(true);
    try {
      const hashedPassword = await hashPassword(data.password);
      const response = await fetch('/api/auth/update-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, password: hashedPassword }),
      });
      console.log(response);
      reset();
      navigate('/login');
    } catch (error) {
      console.error('Submission failed:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <div className="flex flex-row align-middle items-center">
          <Link
            type="button"
            to="/login"
            className="rounded-xl p-1 mr-2 text-graphite-900 hover:bg-graphite-25 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pine-600"
          >
            <ChevronLeftIcon aria-hidden="true" className="h-8 w-8" />
          </Link>
          <span className="text-2xl font-semibold leading-9 text-graphite-900">
            Update your password
          </span>
        </div>
        <p className="mt-2 text-sm leading-6 text-graphite-900">
          Choose your new password. Remember to always keep your passwords safe.
        </p>
      </div>
      <div className="mt-8">
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            className="space-y-6"
          >
            <InputField
              id="password"
              name="password"
              type="password"
              label="Password"
              placeholder="••••••••"
              register={register}
              autoFocus={true}
              validationSchema={{
                required: 'This field is required',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters',
                },
                validate: {
                  hasUpperCase: (value: string) =>
                    /[A-Z]/.test(value) ||
                    'Password must contain at least one uppercase letter',
                  hasLowerCase: (value: string) =>
                    /[a-z]/.test(value) ||
                    'Password must contain at least one lowercase letter',
                  hasNumber: (value: string) =>
                    /\d/.test(value) ||
                    'Password must contain at least one number',
                  hasSpecialChar: (value: string) =>
                    /[!@#$%^&*(),.?":{}|<>]/.test(value) ||
                    'Password must contain at least one special character',
                },
              }}
              errors={errors.password}
            />

            <InputField
              id="confirm-password"
              name="confirmPassword"
              type="password"
              label="Confirm password"
              placeholder="••••••••"
              register={register}
              validationSchema={{
                required: 'This field is required',
                validate: (value: string) =>
                  value === watch('password') || 'Passwords do not match',
              }}
              errors={errors.confirmPassword}
            />

            <Button type="submit" loading={loading}>
              Continue
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}
