import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '../../common/catalyst/dialog';
import { Field, Label } from '../../common/catalyst/fieldset';
import { Input } from '../../common/catalyst/input';
import { Select } from '../../common/catalyst/select';
import { Button } from '../../common/catalyst/button';
import { ErrorMessage } from '../../common/catalyst/fieldset';
import { useCurrentOrganisation } from '../../../hooks/useCurrentOrganisation';
import {
  Alert,
  AlertTitle,
  AlertDescription,
  AlertActions,
} from '../../common/catalyst/alert';
import { EditUserFormInputs } from '../../../types/teamMembers';

interface EditUserDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: EditUserFormInputs) => Promise<void>;
  user: EditUserFormInputs;
  isSaving: boolean;
}

export function EditUserDialog({
  isOpen,
  onClose,
  onSave,
  user,
  isSaving,
}: EditUserDialogProps) {
  const { currentOrganisation } = useCurrentOrganisation();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<EditUserFormInputs>();
  const team = watch('userRole.team', '');
  const [showAlert, setShowAlert] = useState(false);
  const [formData, setFormData] = useState<EditUserFormInputs | null>(null);

  useEffect(() => {
    if (isOpen && user) {
      reset(user); // Reset the form with the user data
    }
  }, [isOpen, user, reset]);

  const teamOptions = [
    'Board',
    'Executive Leadership',
    'Operations',
    'Programs',
    'Marketing',
    'Fundraising',
    'Finance',
    'Information Technology',
    'Human Resources',
    'Volunteers',
    'Other',
  ];

  const getRoleOptions = (email: string) => {
    if (email) {
      const emailDomain = email.split('@')[1];
      if (emailDomain === currentOrganisation?.domain) {
        return ['User', 'Admin', 'Super Admin', 'Account Owner'];
      } else {
        return ['Guest', 'Consultant'];
      }
    } else {
      return [
        'User',
        'Admin',
        'Super Admin',
        'Guest',
        'Consultant',
        'Account Owner',
      ];
    }
  };

  const roleOptions = getRoleOptions(user?.user.email).filter((option) => {
    // Prevent changing the account owner's role
    if (user.userRole.role === 'Account Owner') {
      return option === 'Account Owner';
    } else {
      return option !== 'Account Owner';
    }
  });

  const isExternalUser = ['Guest', 'Consultant'].includes(user?.userRole.role);

  const isAccountOwner = user.userRole.role === 'Account Owner';

  const onSubmit = async (data: EditUserFormInputs) => {
    if (
      ((data.userRole.role === 'Admin' ||
        data.userRole.role === 'Super Admin') &&
        user.userRole.role !== 'Admin' &&
        user.userRole.role !== 'Super Admin') ||
      (data.userRole.role === 'Consultant' && user.userRole.role === 'Guest')
    ) {
      setFormData(data);
      setShowAlert(true);
    } else {
      await onSave(data);
      onClose();
    }
  };

  const handleConfirm = async () => {
    if (formData) {
      await onSave(formData);
      onClose();
    }
    setShowAlert(false);
  };

  const handleCancel = () => {
    setShowAlert(false);
  };

  const getPermissions = (role: string) => {
    switch (role) {
      case 'Admin':
        return 'access to view and edit all user data and settings';
      case 'Super Admin':
        return 'access to view and edit all organisation data and settings, including billing';
      case 'Consultant':
        return 'access equivalent to an Admin user';
      default:
        return '';
    }
  };

  const getAlertTitle = (role: string, firstName: string, lastName: string) => {
    const article = /^[aeiou]/i.test(role) ? 'an' : 'a';
    return `Make ${firstName} ${lastName} ${article} ${role}?`;
  };

  const getAlertDescription = (role: string, firstName: string) => {
    const article = /^[aeiou]/i.test(role) ? 'an' : 'a';
    const permissions = getPermissions(role);
    return `As ${article} ${role}, ${firstName} will have ${permissions}.`;
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Edit user</DialogTitle>
        <DialogDescription>Update the details for the user.</DialogDescription>
        <DialogBody>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-12">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 border-b border-gray-900/10 pb-12 md:grid-cols-6">
              <Field className="sm:col-span-3">
                <Label>First name</Label>
                <Input
                  {...register('user.firstName', {
                    required: 'First name is required',
                  })}
                  placeholder="First name"
                  disabled={isExternalUser}
                />
                {errors.user?.firstName && (
                  <ErrorMessage className="text-left">
                    {errors.user.firstName.message}
                  </ErrorMessage>
                )}
              </Field>

              <Field className="sm:col-span-3">
                <Label>Last name</Label>
                <Input
                  {...register('user.lastName', {
                    required: 'Last name is required',
                  })}
                  placeholder="Last name"
                  disabled={isExternalUser}
                />
                {errors.user?.lastName && (
                  <ErrorMessage className="text-left">
                    {errors.user.lastName.message}
                  </ErrorMessage>
                )}
              </Field>

              <Field className="sm:col-span-2">
                <Label>Role</Label>
                <Select
                  {...register('userRole.role', {
                    required: 'Role is required',
                  })}
                  disabled={isAccountOwner}
                >
                  {roleOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
                {errors.userRole?.role && (
                  <ErrorMessage className="text-left">
                    {errors.userRole.role.message}
                  </ErrorMessage>
                )}
              </Field>

              <Field className="sm:col-span-4">
                <Label>Title</Label>
                <Input
                  {...register('userRole.title', {
                    required: 'Title is required',
                  })}
                  placeholder="Title"
                />
                {errors.userRole?.title && (
                  <ErrorMessage className="text-left">
                    {errors.userRole.title.message}
                  </ErrorMessage>
                )}
              </Field>

              <Field className={`sm:col-span-${team === 'Other' ? '3' : '6'}`}>
                <Label>Team</Label>
                <Select
                  {...register('userRole.team', {
                    required: 'Team is required',
                  })}
                >
                  <option value="">Select a team</option>
                  {teamOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
                {errors.userRole?.team && (
                  <ErrorMessage className="text-left">
                    {errors.userRole.team.message}
                  </ErrorMessage>
                )}
              </Field>

              {team === 'Other' && (
                <Field className="sm:col-span-3">
                  <Label>Other team</Label>
                  <Input
                    {...register('userRole.otherTeam', {
                      required: 'Please specify your team',
                    })}
                    placeholder="Please specify your team"
                  />
                  {errors.userRole?.otherTeam && (
                    <ErrorMessage className="text-left">
                      {errors.userRole.otherTeam.message}
                    </ErrorMessage>
                  )}
                </Field>
              )}
            </div>
          </form>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={isSaving}
            className="min-h-[38px] flex items-center justify-center"
          >
            {isSaving ? (
              <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            ) : (
              'Save'
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {showAlert && formData && (
        <Alert size="md" open={showAlert} onClose={handleCancel}>
          <AlertTitle>
            {getAlertTitle(
              formData.userRole.role,
              formData.user.firstName,
              formData.user.lastName
            )}
          </AlertTitle>
          <AlertDescription>
            {getAlertDescription(
              formData.userRole.role,
              formData.user.firstName
            )}
          </AlertDescription>
          <AlertActions>
            <Button plain onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleConfirm}>Confirm</Button>
          </AlertActions>
        </Alert>
      )}
    </>
  );
}
