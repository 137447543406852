import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useUsers } from '../../../hooks/useUsers';
import { useCurrentOrganisation } from '../../../hooks/useCurrentOrganisation';
import { OrganisationUser } from '../../../types';
import { useUserPermissions } from '../../../hooks/useUserPermissions';
import { adminResetPasswordApi } from '../../../services/userService';
import { Button } from '../../common/catalyst/button';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../common/catalyst/table-custom';
import {
  MagnifyingGlassIcon,
  FunnelIcon,
  PlusIcon,
  XMarkIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  EllipsisVerticalIcon,
} from '@heroicons/react/20/solid';
import {
  Pagination,
  PaginationList,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
} from '../../common/catalyst/pagination';
import { Avatar } from '../../common/catalyst/avatar';
import {
  Dropdown,
  DropdownButton,
  DropdownMenu,
  DropdownItem,
  DropdownItemButton,
} from '../../common/catalyst/dropdown';
import { AddUserDialog } from '../dialogs/AddUserDialog';
import { EditUserDialog } from '../dialogs/EditUserDialog';
import {
  Alert,
  AlertTitle,
  AlertDescription,
  AlertActions,
} from '../../common/catalyst/alert';
import { UpdateEmailDialog } from '../dialogs/UpdateEmailDialog';
import { Badge } from '../../common/catalyst/badge';
import {
  AddUserFormInputs,
  EditUserFormInputs,
  UpdateEmailFormInputs,
} from '../../../types';
import { useToastStore } from '../../../stores/toastStore';
import Toast from '../../common/Toast';
import { AxiosError } from 'axios';

interface UserToResendInvitation {
  id: string;
  email: string;
}

interface Filters {
  status: string[];
  team: string[];
  role: string[];
}

const NoResults = ({ clearFilters }: { clearFilters: () => void }) => (
  <div className="space-y-6">
    <Table>
      <TableHead>
        <TableRow>
          <TableHeader className="w-4 pr-0">
            <div className="relative flex h-6 items-center">
              <input
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-zinc-600 focus:ring-zinc-600"
                disabled
              />
            </div>
          </TableHeader>
          <TableHeader>Name</TableHeader>
          <TableHeader>Title</TableHeader>
          <TableHeader>Role</TableHeader>
          <TableHeader>Last login</TableHeader>
          <TableHeader>
            <span className="sr-only">Edit</span>
          </TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell colSpan={6} className="text-center">
            <div className="flex flex-col items-center p-12">
              <MagnifyingGlassIcon
                className="mx-auto h-12 w-12 text-gray-400"
                aria-hidden="true"
              />
              <h3 className="mt-2 text-base font-semibold leading-6 text-gray-900">
                No results found
              </h3>
              <p className="mt-2 text-sm text-gray-700">
                No users match your current filters. Try adjusting your search
                or filter criteria.
              </p>
              <div className="mt-6">
                <Button type="button" onClick={clearFilters}>
                  Clear filters
                </Button>
              </div>
            </div>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </div>
);

export function TeamMembers() {
  const { currentOrganisation } = useCurrentOrganisation();
  const {
    users,
    addUser,
    deleteUser,
    updateUser,
    reactivateUser,
    makeAccountOwner,
    updateUserEmail,
    resendInvitation,
    deleteUsers,
  } = useUsers(currentOrganisation?.id || '');
  const { hasPermission } = useUserPermissions();
  const [searchParams, setSearchParams] = useSearchParams();
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState<Filters>({
    status: [],
    team: [],
    role: [],
  });
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditUserOpen, setIsEditUserOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<EditUserFormInputs | null>(
    null
  );
  const [showRemoveAlert, setShowRemoveAlert] = useState(false);
  const [userToRemove, setUserToRemove] = useState<OrganisationUser | null>(
    null
  );
  const [isUpdateEmailOpen, setIsUpdateEmailOpen] = useState(false);
  const [emailToUpdate, setEmailToUpdate] = useState<string | null>(null);
  const [isSavingEmail, setIsSavingEmail] = useState(false);
  const [userIdToUpdate, setUserIdToUpdate] = useState<string | null>(null);
  const [userRoleToUpdate, setUserRoleToUpdate] = useState<string | null>(null);

  const showToast = useToastStore((state) => state.show);

  const [showBulkRemoveAlert, setShowBulkRemoveAlert] = useState(false);
  const [showResetPasswordConfirm, setShowResetPasswordConfirm] =
    useState(false);
  const [emailToReset, setEmailToReset] = useState<string | null>(null);

  const [showMakeAccountOwnerConfirm, setShowMakeAccountOwnerConfirm] =
    useState(false);
  const [userToMakeAccountOwner, setUserToMakeAccountOwner] =
    useState<OrganisationUser | null>(null);

  const [showResendInvitationConfirm, setShowResendInvitationConfirm] =
    useState(false);
  const [userToResendInvitation, setUserToResendInvitation] =
    useState<UserToResendInvitation | null>(null);
  const [isResendingInvitation, setIsResendingInvitation] = useState(false);

  const teamOptions = [
    'Board',
    'Executive Leadership',
    'Operations',
    'Programs',
    'Marketing',
    'Fundraising',
    'Finance',
    'Information Technology',
    'Human Resources',
    'Volunteers',
    'Other',
  ];

  useEffect(() => {
    const page = parseInt(searchParams.get('page') || '1', 10);
    setCurrentPage(page);

    const statusFilter = searchParams.get('status')?.split(',') || [];
    const teamFilter = searchParams.get('team')?.split(',') || [];
    const roleFilter = searchParams.get('role')?.split(',') || [];
    const search = searchParams.get('search') || '';
    const itemsPerPage = parseInt(searchParams.get('itemsPerPage') || '6', 10);
    setItemsPerPage(itemsPerPage);

    setFilters({
      status: statusFilter,
      team: teamFilter,
      role: roleFilter,
    });
    setSearchTerm(search);
  }, [searchParams]);

  if (!currentOrganisation) {
    return <div>Loading organisation data...</div>;
  }

  const updateUrlWithFilters = (
    newFilters: Filters,
    newPage: number = 1,
    newSearch: string = searchTerm,
    newItemsPerPage: number = itemsPerPage,
    removeItemsPerPage: boolean = false
  ) => {
    const params = new URLSearchParams();
    params.set('page', newPage.toString());
    if (newSearch) params.set('search', newSearch);
    if (!removeItemsPerPage && newItemsPerPage !== filteredUsers.length)
      params.set('itemsPerPage', newItemsPerPage.toString());
    Object.entries(newFilters).forEach(([key, value]) => {
      if (value.length > 0) {
        params.set(key, value.join(','));
      }
    });
    setSearchParams(params);
  };

  const toggleFilter = (category: keyof Filters, value: string) => {
    const newFilters = {
      ...filters,
      [category]: filters[category].includes(value)
        ? filters[category].filter((item) => item !== value)
        : [...filters[category], value],
    };
    setFilters(newFilters);
    updateUrlWithFilters(newFilters);
  };

  const filteredUsers = users
    .filter((user) => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      return (
        (searchTerm === '' ||
          fullName.includes(searchTerm.toLowerCase()) ||
          user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.UserRole?.title
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          user.UserRole?.team
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) &&
        (filters.status.length === 0 ||
          filters.status.includes(user.UserRole.status || 'Active')) &&
        (filters.team.length === 0 ||
          filters.team.includes(user.UserRole.team)) &&
        (filters.role.length === 0 || filters.role.includes(user.UserRole.role))
      );
    })
    .sort((a, b) =>
      `${a.lastName} ${a.firstName}`.localeCompare(
        `${b.lastName} ${b.firstName}`
      )
    );

  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const handlePageChange = (page: number) => {
    updateUrlWithFilters(filters, page);
  };

  const handleItemsPerPageChange = (newItemsPerPage: number) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
    if (newItemsPerPage === 0) {
      updateUrlWithFilters(filters, 1, searchTerm, newItemsPerPage, true);
    } else {
      updateUrlWithFilters(filters, 1, searchTerm, newItemsPerPage);
    }
  };

  const toggleSelectAll = () => {
    if (selectedUsers.length === users.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(users.map((user) => user.id));
    }
  };

  const toggleSelectPerson = (id: string) => {
    setSelectedUsers((prev) =>
      prev.includes(id) ? prev.filter((e) => e !== id) : [...prev, id]
    );
  };

  const getInitials = (firstName: string, lastName: string) => {
    return (firstName[0] + lastName[0]).toUpperCase();
  };

  type ColorName =
    | 'red-500'
    | 'orange-500'
    | 'yellow-500'
    | 'green-500'
    | 'teal-500'
    | 'blue-500'
    | 'indigo-500'
    | 'violet-500'
    | 'red-700'
    | 'red-900'
    | 'orange-700'
    | 'orange-900'
    | 'yellow-700'
    | 'yellow-900'
    | 'green-700'
    | 'green-900'
    | 'teal-700'
    | 'teal-900'
    | 'blue-700'
    | 'blue-900'
    | 'indigo-700'
    | 'indigo-900'
    | 'violet-700'
    | 'violet-900';

  const getOrganisationColor = (colorName: string): string => {
    const colorMap: Record<ColorName, string> = {
      'red-500': 'bg-red-500',
      'orange-500': 'bg-orange-500',
      'yellow-500': 'bg-yellow-500',
      'green-500': 'bg-green-500',
      'teal-500': 'bg-teal-500',
      'blue-500': 'bg-blue-500',
      'indigo-500': 'bg-indigo-500',
      'violet-500': 'bg-violet-500',
      'red-700': 'bg-red-700',
      'red-900': 'bg-red-900',
      'orange-700': 'bg-orange-700',
      'orange-900': 'bg-orange-900',
      'yellow-700': 'bg-yellow-700',
      'yellow-900': 'bg-yellow-900',
      'green-700': 'bg-green-700',
      'green-900': 'bg-green-900',
      'teal-700': 'bg-teal-700',
      'teal-900': 'bg-teal-900',
      'blue-700': 'bg-blue-700',
      'blue-900': 'bg-blue-900',
      'indigo-700': 'bg-indigo-700',
      'indigo-900': 'bg-indigo-900',
      'violet-700': 'bg-violet-700',
      'violet-900': 'bg-violet-900',
    };

    return colorName in colorMap
      ? colorMap[colorName as ColorName]
      : 'bg-pine-800';
  };

  const clearSearch = () => {
    setSearchTerm('');
    updateUrlWithFilters(filters, 1, '');
  };

  const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      updateUrlWithFilters(filters, 1, searchTerm);
    } else if (e.key === 'Escape') {
      clearSearch();
    }
  };

  const getActiveFilterCount = () => {
    return Object.values(filters).filter((category) => category.length > 0)
      .length;
  };

  const clearAllFilters = () => {
    const emptyFilters = {
      status: [],
      team: [],
      role: [],
    };
    setFilters(emptyFilters);
    updateUrlWithFilters(emptyFilters);
  };

  const showSuccessToast = (message: string) => {
    showToast(message, 'success');
  };

  const handleAddUser = async (data: AddUserFormInputs) => {
    setIsSaving(true);
    try {
      await addUser(data);
      setIsAddUserOpen(false);
      showSuccessToast('User added successfully.');
    } catch (error: unknown) {
      console.error('Error adding user:', error);
      if (error instanceof AxiosError && error.response?.data?.message) {
        showToast(error.response.data.message, 'error');
      } else {
        showToast('An error occurred while adding the user.', 'error');
      }
    } finally {
      setIsSaving(false);
    }
  };

  const closeAddUser = () => {
    setIsAddUserOpen(false);
  };

  const handleUpdateEmail = (userId: string, email: string, role: string) => {
    setEmailToUpdate(email);
    setUserIdToUpdate(userId);
    setUserRoleToUpdate(role);
    setIsUpdateEmailOpen(true);
  };

  const handleSaveEmail = async (data: UpdateEmailFormInputs) => {
    setIsSavingEmail(true);
    try {
      await updateUserEmail(data);
      showSuccessToast('Email address updated successfully');
    } catch (error) {
      console.error('Error updating email:', error);
      showToast('An error occurred while updating the email address', 'error');
    } finally {
      setIsSavingEmail(false);
      setIsUpdateEmailOpen(false);
    }
  };

  const handleMakeAccountOwner = async (user: OrganisationUser) => {
    setUserToMakeAccountOwner(user);
    setShowMakeAccountOwnerConfirm(true);
  };

  const confirmMakeAccountOwner = async () => {
    if (userToMakeAccountOwner) {
      try {
        await makeAccountOwner(userToMakeAccountOwner.id);
        showSuccessToast('Account owner changed successfully');
      } catch (error) {
        console.error('Error making account owner:', error);
        showToast(
          'An error occurred while changing the account owner',
          'error'
        );
      } finally {
        setShowMakeAccountOwnerConfirm(false);
        setUserToMakeAccountOwner(null);
      }
    }
  };

  const cancelMakeAccountOwner = () => {
    setShowMakeAccountOwnerConfirm(false);
    setUserToMakeAccountOwner(null);
  };

  const handleResendInvitation = (user: { id: string; email: string }) => {
    setUserToResendInvitation({ id: user.id, email: user.email });
    setShowResendInvitationConfirm(true);
  };

  const confirmResendInvitation = async () => {
    if (userToResendInvitation) {
      setIsResendingInvitation(true);
      try {
        await resendInvitation(userToResendInvitation.id);
        showSuccessToast('Invitation email resent');
      } catch (error) {
        console.error('Error resending invitation:', error);
        showToast('An error occurred while resending the invitation', 'error');
      } finally {
        setIsResendingInvitation(false);
        setShowResendInvitationConfirm(false);
        setUserToResendInvitation(null);
      }
    }
  };

  const cancelResendInvitation = () => {
    setShowResendInvitationConfirm(false);
    setUserToResendInvitation(null);
  };

  const handleResetPassword = async () => {
    if (emailToReset) {
      setIsSavingEmail(true);
      try {
        await adminResetPasswordApi(currentOrganisation.id, emailToReset);
        showSuccessToast('Password reset email sent');
      } catch (error) {
        console.error('Error resetting password:', error);
        if (error instanceof AxiosError && error.response?.status === 403) {
          showToast(
            'Password reset is not available for external users',
            'error'
          );
        } else {
          showToast('An error occurred while resetting the password', 'error');
        }
      } finally {
        setIsSavingEmail(false);
        setShowResetPasswordConfirm(false);
        setEmailToReset(null);
      }
    }
  };

  const confirmResetPassword = (email: string) => {
    setEmailToReset(email);
    setShowResetPasswordConfirm(true);
  };

  const handleRemove = (user: OrganisationUser) => {
    setUserToRemove(user);
    setShowRemoveAlert(true);
  };

  const confirmRemove = async () => {
    if (userToRemove) {
      try {
        await deleteUser(userToRemove.id);
        setShowRemoveAlert(false);
        setUserToRemove(null);
        showSuccessToast('User successfully removed');
      } catch (error) {
        console.error('Error removing user:', error);
      }
    }
  };

  const cancelRemove = () => {
    setShowRemoveAlert(false);
    setUserToRemove(null);
  };

  const handleRemoveSelectedUsers = () => {
    setShowBulkRemoveAlert(true);
  };

  const confirmBulkRemove = async () => {
    try {
      await deleteUsers(selectedUsers);
      setSelectedUsers([]);
      setShowBulkRemoveAlert(false);
      showSuccessToast(
        `Successfully removed ${selectedUsers.length > 1 ? `${selectedUsers.length} users` : '1 user'}`
      );
    } catch (error) {
      console.error('Error removing selected users:', error);
      showToast('An error occurred while removing selected users', 'error');
    }
  };

  const cancelBulkRemove = () => {
    setShowBulkRemoveAlert(false);
  };

  const handleResetPasswords = () => {
    // TODO: Implement the logic to reset passwords for selected users
  };

  const handleReactivateUser = async (userId: string) => {
    try {
      await reactivateUser(userId);
      showSuccessToast('User reactivated successfully');
    } catch (error) {
      console.error('Error reactivating user:', error);
      showToast('An error occurred while reactivating the user', 'error');
    }
  };

  // Function to handle opening the edit dialog
  const handleEditUser = (user: OrganisationUser) => {
    const userData: EditUserFormInputs = {
      user: {
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      },
      userRole: {
        id: user.UserRole.id,
        role: user.UserRole.role,
        title: user.UserRole.title,
        team: teamOptions.includes(user.UserRole.team)
          ? user.UserRole.team
          : 'Other',
        otherTeam: teamOptions.includes(user.UserRole.team)
          ? ''
          : user.UserRole.team,
      },
    };
    setSelectedUser(userData);
    setIsEditUserOpen(true);
  };

  const handleSaveUser = async (data: EditUserFormInputs) => {
    setIsSaving(true);
    try {
      await updateUser(data);
      setIsEditUserOpen(false);
    } catch (error) {
      console.error('Error updating user:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Team members
        </h2>
        <p className="mt-2 text-sm text-gray-700">
          Manage users for your organisation.
        </p>
      </div>
      <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center">
          <div className="relative rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              name="search"
              id="search"
              className="block w-full rounded-md border-0 py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
              placeholder="Search users"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleSearchKeyDown}
            />
            {searchTerm && (
              <button
                type="button"
                className="absolute inset-y-0 right-0 flex items-center pr-3"
                onClick={clearSearch}
              >
                <XMarkIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </button>
            )}
          </div>
          <Dropdown>
            <div className="relative">
              <DropdownButton as={Button} outline className="ml-3">
                <FunnelIcon className="h-5 w-5" aria-hidden="true" />
                <span className="sr-only">Filter</span>
              </DropdownButton>
              {getActiveFilterCount() > 0 && (
                <button
                  className="absolute -bottom-2 -right-2 flex h-5 w-5 items-center justify-center rounded-full bg-zinc-600 text-xs text-white transition-colors hover:bg-black group"
                  onClick={(e) => {
                    e.stopPropagation();
                    clearAllFilters();
                  }}
                >
                  <span className="group-hover:hidden">
                    {getActiveFilterCount()}
                  </span>
                  <XMarkIcon className="h-3 w-3 hidden group-hover:block" />
                </button>
              )}
            </div>
            <DropdownMenu anchor="right start">
              <Dropdown>
                <DropdownItemButton>
                  Team
                  <ChevronRightIcon aria-hidden="true" data-slot="icon" />
                </DropdownItemButton>
                <DropdownMenu anchor="right start">
                  {[
                    'Board',
                    'Executive Leadership',
                    'Operations',
                    'Programs',
                    'Marketing',
                    'Fundraising',
                    'Finance',
                    'Information Technology',
                    'Human Resources',
                    'Volunteers',
                  ].map((team) => (
                    <DropdownItem
                      key={team}
                      onClick={() => toggleFilter('team', team)}
                    >
                      {team} {filters.team.includes(team) && '✓'}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              <Dropdown>
                <DropdownItemButton>
                  Role
                  <ChevronRightIcon aria-hidden="true" data-slot="icon" />
                </DropdownItemButton>
                <DropdownMenu anchor="right start">
                  {[
                    'Account Owner',
                    'Super Admin',
                    'Admin',
                    'User',
                    'Guest',
                    'Consultant',
                  ].map((role) => (
                    <DropdownItem
                      key={role}
                      onClick={() => toggleFilter('role', role)}
                    >
                      {role} {filters.role.includes(role) && '✓'}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              <Dropdown>
                <DropdownItemButton>
                  Status
                  <ChevronRightIcon aria-hidden="true" data-slot="icon" />
                </DropdownItemButton>
                <DropdownMenu anchor="right start">
                  {['Active', 'Invited', 'Expired', 'Removed'].map((status) => (
                    <DropdownItem
                      key={status}
                      onClick={() => toggleFilter('status', status)}
                    >
                      {status} {filters.status.includes(status) && '✓'}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </DropdownMenu>
          </Dropdown>
          <div className="ml-3">
            <Dropdown>
              <DropdownButton outline>
                {itemsPerPage} per page
                <ChevronDownIcon className="h-5 w-5 ml-2" aria-hidden="true" />
              </DropdownButton>
              <DropdownMenu anchor="bottom start">
                {[6, 12, 24, 48].map((option) => (
                  <DropdownItem
                    key={option}
                    onClick={() => handleItemsPerPageChange(option)}
                  >
                    {option}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <div className="flex items-center">
          {selectedUsers.length > 0 && (
            <Dropdown>
              <DropdownButton outline>
                {selectedUsers.length} selected
                <ChevronDownIcon className="h-5 w-5 ml-2" aria-hidden="true" />
              </DropdownButton>
              <DropdownMenu anchor="bottom start">
                <DropdownItem onClick={() => handleRemoveSelectedUsers()}>
                  Remove users
                </DropdownItem>
                <DropdownItem onClick={() => handleResetPasswords()}>
                  Reset user passwords
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
          <Button
            type="button"
            onClick={() => setIsAddUserOpen(true)}
            className="ml-3"
          >
            <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
            Add user
          </Button>
        </div>
      </div>
      {filteredUsers.length > 0 ? (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader className="w-4 pr-0">
                  <div className="relative flex h-6 items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-zinc-600 focus:ring-zinc-600"
                      checked={selectedUsers.length === users.length}
                      onChange={toggleSelectAll}
                    />
                  </div>
                </TableHeader>
                <TableHeader>Name</TableHeader>
                <TableHeader>Title</TableHeader>
                <TableHeader>Role</TableHeader>
                <TableHeader>Last active</TableHeader>
                <TableHeader>
                  <span className="sr-only">Edit</span>
                </TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedUsers.map((user) => (
                <TableRow key={user.id}>
                  <TableCell className="w-4 pr-0">
                    <div className="relative flex h-6 items-center">
                      <input
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-zinc-600 focus:ring-zinc-600"
                        checked={selectedUsers.includes(user.id)}
                        onChange={() => toggleSelectPerson(user.id)}
                      />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center">
                      <Avatar
                        initials={getInitials(user.firstName, user.lastName)}
                        className={`${getOrganisationColor(currentOrganisation?.colour || '')} text-white size-10 mr-4`}
                        square
                        alt={`${user.firstName} ${user.lastName}`}
                      />
                      <div>
                        <div className="flex">
                          <div className="font-medium text-gray-900 mr-2">{`${user.firstName} ${user.lastName}`}</div>
                          <Badge
                            color={
                              user.UserRole.status === 'Active'
                                ? 'green'
                                : user.UserRole.status === 'Removed'
                                  ? 'red'
                                  : undefined
                            }
                          >
                            {user.UserRole.status}
                          </Badge>
                        </div>
                        <div className="text-gray-500">{user.email}</div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="text-gray-900">{user.UserRole?.title}</div>
                    <div className="text-gray-500">{user.UserRole?.team}</div>
                  </TableCell>
                  <TableCell>{user.UserRole?.role}</TableCell>
                  <TableCell>
                    {user.UserRole?.lastActivity
                      ? new Date(
                          user.UserRole?.lastActivity
                        ).toLocaleDateString()
                      : "Hasn't logged in"}
                  </TableCell>
                  <TableCell>
                    <Dropdown>
                      <DropdownButton plain>
                        <span className="sr-only">Options</span>
                        <EllipsisVerticalIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </DropdownButton>
                      <DropdownMenu anchor="bottom end">
                        {user.UserRole.status === 'Removed' ? (
                          <DropdownItem
                            onClick={() => handleReactivateUser(user.id)}
                          >
                            Reactivate
                          </DropdownItem>
                        ) : (
                          <>
                            <DropdownItem onClick={() => handleEditUser(user)}>
                              Edit
                            </DropdownItem>
                            {!['Guest', 'Consultant'].includes(
                              user.UserRole.role
                            ) && (
                              <DropdownItem
                                onClick={() =>
                                  handleUpdateEmail(
                                    user.id,
                                    user.email,
                                    user.UserRole.role
                                  )
                                }
                              >
                                Update email
                              </DropdownItem>
                            )}
                            {hasPermission('account owner') &&
                              (user.UserRole.role === 'Admin' ||
                                user.UserRole.role === 'Super Admin') &&
                              user.UserRole.status === 'Active' && (
                                <DropdownItem
                                  onClick={() => handleMakeAccountOwner(user)}
                                >
                                  Make account owner
                                </DropdownItem>
                              )}
                            {['Invited', 'Expired'].includes(
                              user.UserRole.status
                            ) && (
                              <DropdownItem
                                onClick={() => handleResendInvitation(user)}
                              >
                                Resend invitation
                              </DropdownItem>
                            )}
                            {!['Guest', 'Consultant'].includes(
                              user.UserRole.role
                            ) &&
                              user.UserRole.status !== 'Invited' && (
                                <DropdownItem
                                  onClick={() =>
                                    confirmResetPassword(user.email)
                                  }
                                >
                                  Reset password
                                </DropdownItem>
                              )}
                            <DropdownItem onClick={() => handleRemove(user)}>
                              Remove
                            </DropdownItem>
                          </>
                        )}
                      </DropdownMenu>
                    </Dropdown>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {itemsPerPage !== 0 && totalPages > 1 && (
            <Pagination>
              <PaginationPrevious
                href={currentPage === 1 ? null : `?page=${currentPage - 1}`}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.preventDefault();
                  if (currentPage > 1) handlePageChange(currentPage - 1);
                }}
              />
              <PaginationList>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (page) => (
                    <PaginationPage
                      key={page}
                      href={`?page=${page}`}
                      current={currentPage === page}
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.preventDefault();
                        handlePageChange(page);
                      }}
                    >
                      {page}
                    </PaginationPage>
                  )
                )}
              </PaginationList>
              <PaginationNext
                href={
                  currentPage === totalPages ? null : `?page=${currentPage + 1}`
                }
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.preventDefault();
                  if (currentPage < totalPages)
                    handlePageChange(currentPage + 1);
                }}
              />
            </Pagination>
          )}
        </>
      ) : (
        <NoResults clearFilters={clearAllFilters} />
      )}
      {currentOrganisation && (
        <AddUserDialog
          isOpen={isAddUserOpen}
          onClose={closeAddUser}
          onSave={handleAddUser}
          organizationDomain={currentOrganisation.domain}
          isSaving={isSaving}
          currentOrganisation={currentOrganisation}
        />
      )}
      {selectedUser && (
        <EditUserDialog
          isOpen={isEditUserOpen}
          onClose={() => setIsEditUserOpen(false)}
          onSave={handleSaveUser}
          user={selectedUser}
          isSaving={isSaving}
        />
      )}
      {isUpdateEmailOpen &&
        userIdToUpdate &&
        userRoleToUpdate &&
        currentOrganisation &&
        emailToUpdate && (
          <UpdateEmailDialog
            isOpen={isUpdateEmailOpen}
            onClose={() => setIsUpdateEmailOpen(false)}
            onSave={handleSaveEmail}
            isSaving={isSavingEmail}
            currentEmail={emailToUpdate}
            userId={userIdToUpdate}
            userRole={userRoleToUpdate}
            organisationDomain={currentOrganisation.domain}
            organisationId={currentOrganisation.id}
          />
        )}
      {showRemoveAlert && userToRemove && (
        <Alert size="md" open={showRemoveAlert} onClose={cancelRemove}>
          <AlertTitle>
            Remove {userToRemove.firstName} {userToRemove.lastName} from{' '}
            {currentOrganisation?.name}?
          </AlertTitle>
          <AlertDescription>
            {userToRemove.firstName} will no longer have access to your account.
            This action cannot be undone.
          </AlertDescription>
          <AlertActions>
            <Button plain onClick={cancelRemove}>
              Cancel
            </Button>
            <Button onClick={confirmRemove}>Confirm</Button>
          </AlertActions>
        </Alert>
      )}
      {showBulkRemoveAlert && (
        <Alert size="md" open={showBulkRemoveAlert} onClose={cancelBulkRemove}>
          <AlertTitle>
            Remove {selectedUsers.length} selected{' '}
            {selectedUsers.length === 1 ? 'user' : 'users'} from{' '}
            {currentOrganisation?.name}?
          </AlertTitle>
          <AlertDescription>
            The selected {selectedUsers.length === 1 ? 'user' : 'users'} will no
            longer have access to your account. This action cannot be undone.
          </AlertDescription>
          <AlertActions>
            <Button plain onClick={cancelBulkRemove}>
              Cancel
            </Button>
            <Button onClick={confirmBulkRemove}>Confirm</Button>
          </AlertActions>
        </Alert>
      )}
      <Toast />
      {showResetPasswordConfirm && (
        <Alert
          size="md"
          open={showResetPasswordConfirm}
          onClose={() => setShowResetPasswordConfirm(false)}
        >
          <AlertTitle>Reset Password</AlertTitle>
          <AlertDescription>
            Are you sure you want to send a password reset email to{' '}
            {emailToReset}?
          </AlertDescription>
          <AlertActions>
            <Button plain onClick={() => setShowResetPasswordConfirm(false)}>
              Cancel
            </Button>
            <Button
              onClick={handleResetPassword}
              disabled={isSavingEmail}
              className="min-h-[38px] flex items-center justify-center"
            >
              {isSavingEmail ? (
                <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              ) : (
                'Confirm'
              )}
            </Button>
          </AlertActions>
        </Alert>
      )}
      {showMakeAccountOwnerConfirm && userToMakeAccountOwner && (
        <Alert
          size="md"
          open={showMakeAccountOwnerConfirm}
          onClose={cancelMakeAccountOwner}
        >
          <AlertTitle>
            Make {userToMakeAccountOwner.firstName}{' '}
            {userToMakeAccountOwner.lastName} the Account Owner?
          </AlertTitle>
          <AlertDescription>
            This action will transfer full ownership of the account to{' '}
            {userToMakeAccountOwner.firstName}. You will be changed to a Super
            Admin. This action cannot be undone.
          </AlertDescription>
          <AlertActions>
            <Button plain onClick={cancelMakeAccountOwner}>
              Cancel
            </Button>
            <Button onClick={confirmMakeAccountOwner}>Confirm</Button>
          </AlertActions>
        </Alert>
      )}
      {showResendInvitationConfirm && userToResendInvitation && (
        <Alert
          size="md"
          open={showResendInvitationConfirm}
          onClose={cancelResendInvitation}
        >
          <AlertTitle>Resend Invitation</AlertTitle>
          <AlertDescription>
            Are you sure you want to resend an invitation email to{' '}
            {userToResendInvitation.email}?
          </AlertDescription>
          <AlertActions>
            <Button plain onClick={cancelResendInvitation}>
              Cancel
            </Button>
            <Button
              onClick={confirmResendInvitation}
              disabled={isResendingInvitation}
              className="min-h-[38px] flex items-center justify-center"
            >
              {isResendingInvitation ? (
                <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              ) : (
                'Confirm'
              )}
            </Button>
          </AlertActions>
        </Alert>
      )}
    </div>
  );
}
