import { useState } from 'react';
import { Link } from 'react-router-dom';

import { useForm } from 'react-hook-form';

import { InputField } from '../../components/common/InputField.tsx';
import { Button } from '../../components/common/Button.tsx';

import { ChevronLeftIcon } from '@heroicons/react/20/solid';

interface PasswordResetFormInputs {
  email: string;
}

export default function PasswordReset() {
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(''); // Add state for server error

  const form = useForm<PasswordResetFormInputs>();
  const { register, handleSubmit, formState, reset } = form;
  const { errors } = formState;

  const onSubmit = async (data: PasswordResetFormInputs) => {
    setLoading(true);
    setServerError(''); // Reset server error before submission
    try {
      const email = data.email;
      const response = await fetch('/api/auth/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to send reset email');
      }

      reset();
    } catch (error) {
      console.error('Submission failed:', error);
      setServerError((error as Error).message); // Set server error message
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <div className="flex flex-row align-middle items-center">
          <Link
            type="button"
            to="/login"
            className="rounded-xl p-1 mr-2 text-graphite-900 hover:bg-graphite-25 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pine-600"
          >
            <ChevronLeftIcon aria-hidden="true" className="h-8 w-8" />
          </Link>
          <span className="text-2xl font-semibold leading-9 text-graphite-900">
            Reset your password
          </span>
        </div>
        <p className="mt-2 text-sm leading-6 text-graphite-900">
          Forgot your password? Enter your email below to receive a password
          reset link.
        </p>
      </div>
      <div className="mt-8">
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            className="space-y-6"
          >
            <InputField
              id="email"
              name="email"
              type="text"
              label="Email address"
              placeholder="jane@smith.com"
              register={register}
              autoFocus={true}
              validationSchema={{
                required: 'This field is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Invalid email address',
                },
              }}
              errors={errors.email}
            />

            <Button type="submit" loading={loading}>
              Continue
            </Button>
          </form>

          {serverError && ( // Display server error if it exists
            <div className="mt-1 text-sm text-coral-700">{serverError}</div>
          )}
        </div>
      </div>
    </>
  );
}
