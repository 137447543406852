import { Heading } from '../../components/common/catalyst/heading';
import { ComingSoon } from '../../components/common/ComingSoon';
import { ComingSoonPageContainer } from '../../components/layout/ComingSoonPageContainer';

export default function Architecture() {
  return (
    <ComingSoonPageContainer>
      <Heading className="mb-2">Architecture</Heading>
      <ComingSoon
        title="Visualize Your IT Architecture"
        description="Soon, you'll be able to explore a detailed overview of your organization's IT architecture, including all components and their interactions."
      >
        <li>Gain insights into your IT infrastructure layout</li>
        <li>Identify dependencies and potential bottlenecks</li>
        <li>Optimize resource allocation and usage</li>
        <li>Enhance strategic planning with comprehensive data</li>
      </ComingSoon>
    </ComingSoonPageContainer>
  );
}
