import { Heading } from '../../components/common/catalyst/heading';
import { PageContainer } from '../../components/layout/PageContainer';

export default function Search() {
  return (
    <PageContainer>
      <Heading className="mb-2">Search</Heading>
    </PageContainer>
  );
}
