import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '../../common/catalyst/dialog';
import { Field, Label } from '../../common/catalyst/fieldset';
import { Input } from '../../common/catalyst/input';
import { Button } from '../../common/catalyst/button';
import { ErrorMessage } from '../../common/catalyst/fieldset';

interface EditDomainDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (newDomain: string) => Promise<void>;
  initialDomain: string;
}

export function EditDomainDialog({
  isOpen,
  onClose,
  onSave,
  initialDomain,
}: EditDomainDialogProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { newDomain: initialDomain },
  });

  const onSubmit = async (data: { newDomain: string }) => {
    await onSave(data.newDomain);
    onClose();
  };

  const isValidDomain = (domain: string) => {
    const domainRegex =
      /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
    return domainRegex.test(domain);
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Edit Domain</DialogTitle>
      <DialogDescription>
        Enter a new domain for your organisation. This should be a valid domain
        name.
      </DialogDescription>
      <DialogBody>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Field>
            <Label>Domain</Label>
            <Input
              {...register('newDomain', {
                required: 'Domain is required',
                validate: (value) =>
                  isValidDomain(value) || 'Please enter a valid domain',
              })}
              placeholder="example.com"
              autoFocus
            />
            {errors.newDomain && (
              <ErrorMessage className="text-left">
                {errors.newDomain.message}
              </ErrorMessage>
            )}
          </Field>
        </form>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
