import { useQuery } from '@tanstack/react-query';
import { fetchOrganisation } from '../services/organisationService';
import { Organisation } from '../types';
import { useCurrentUserRole } from './useCurrentUserRole';

export function useCurrentOrganisation() {
  const { currentRole } = useCurrentUserRole();

  const organisationId = currentRole?.organisationId;

  const {
    data: currentOrganisation,
    isLoading,
    isError,
  } = useQuery<Organisation>({
    queryKey: ['organisation', organisationId],
    queryFn: ({ queryKey }) => {
      const [, id] = queryKey;
      return fetchOrganisation(id as string);
    },
    enabled: !!organisationId,
  });

  return {
    currentOrganisation,
    isLoading,
    isError,
  };
}
