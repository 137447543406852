import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { useCurrentUser } from '../../hooks/useCurrentUser.ts';

import { InputField } from '../../components/common/InputField.tsx';
import { Button } from '../../components/common/Button.tsx';
import { SocialLoginButton } from '../../components/common/SocialLoginButton.tsx';

import { RegisterFormInputs } from '../../types/authTypes.ts';

import MicrosoftIcon from '/assets/img/microsoft-logo.svg';
import GoogleIcon from '/assets/img/google-logo.svg';

import { handleOAuthLogin } from '../../utils/oauthLogin.ts';

const personalEmailDomains = [
  'gmail.com',
  'outlook.com',
  'hotmail.com',
  'yahoo.com',
  'aol.com',
  'icloud.com',
];

const isValidDomain = (domain: string) => {
  const domainRegex = /^[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;
  return domainRegex.test(domain);
};

export default function Register() {
  const { register: registerUser } = useCurrentUser();

  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState('');
  const [showDomainField, setShowDomainField] = useState(false);

  const [domainStatus, setDomainStatus] = useState<
    'available' | 'unavailable' | 'checking' | null
  >(null);
  const [domainMessage, setdomainMessage] = useState('');

  const { register, handleSubmit, formState, watch, reset, setValue } =
    useForm<RegisterFormInputs>();
  const { errors } = formState;

  const onSubmit = async (data: RegisterFormInputs) => {
    setLoading(true);
    setServerError('');
    try {
      await registerUser(data);
      reset();
    } catch (error) {
      console.error('Registration error:', error);
      setServerError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const email = watch('email');
  const [validDomain, setValidDomain] = useState('');

  const checkDomainValidity = async (domain: string) => {
    try {
      const response = await fetch('/api/auth/check-domain', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ domain }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error checking domain availability:', error);
      return { isValid: false, message: 'Error checking domain availability' };
    }
  };

  useEffect(() => {
    if (email) {
      const parts = email.split('@');
      if (parts.length === 2) {
        const domain = parts[1].trim().toLowerCase();
        if (
          domain &&
          isValidDomain(domain) &&
          !personalEmailDomains.includes(domain)
        ) {
          setValidDomain(domain);
          setValue('domain', domain);
        } else {
          setValidDomain('');
          setValue('domain', '');
        }
      } else {
        setValidDomain('');
        setValue('domain', '');
      }
    } else {
      setValidDomain('');
      setValue('domain', '');
    }
  }, [email, setValue]);

  const handleEmailBlur = () => {
    if (validDomain) {
      setShowDomainField(true);
    }
  };

  useEffect(() => {
    if (validDomain) {
      setDomainStatus('checking');
      checkDomainValidity(validDomain).then(({ isValid, message }) => {
        setDomainStatus(isValid ? 'available' : 'unavailable');
        setdomainMessage(message);
      });
    }
  }, [validDomain]);

  return (
    <>
      <div>
        <h2 className="text-2xl font-semibold leading-9 text-graphite-900">
          Create an account
        </h2>
        <p className="mt-2 text-sm leading-6 text-graphite-900">
          Already have an account?{' '}
          <Link
            to="/login"
            className="font-semibold text-pine-600 hover:text-pine-500"
          >
            Log in
          </Link>
        </p>
      </div>
      <div className="mt-8">
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className="space-y-6"
        >
          <InputField
            id="email"
            name="email"
            type="text"
            label="Email address"
            placeholder="jane@yourorganisation.org.au"
            register={register}
            autoFocus={true}
            onBlur={handleEmailBlur}
            validationSchema={{
              required: 'This field is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
              validate: (value: string) => {
                const domain = value.split('@')[1];
                return (
                  !personalEmailDomains.includes(domain.toLowerCase()) ||
                  'Please use your work email address'
                );
              },
            }}
            errors={errors.email}
          />

          {showDomainField && (
            <div>
              <InputField
                id="domain"
                name="domain"
                type="text"
                label="Domain"
                value={validDomain}
                register={register}
                disabled={true}
                readOnly={true}
                helpText="This domain will be associated with your organisation on our platform."
                validationSchema={{
                  validate: () => {
                    return (
                      domainStatus === 'available' ||
                      'This domain is already in use. Please use a different domain.'
                    );
                  },
                }}
              />
              {validDomain && domainStatus != 'checking' && (
                <p
                  className={`mt-2 text-xs leading-4 ${domainStatus == 'available' ? 'text-pine-600' : 'text-coral-700'}`}
                >
                  {domainMessage}
                </p>
              )}
            </div>
          )}

          <InputField
            id="password"
            name="password"
            type="password"
            label="Password"
            placeholder="••••••••"
            register={register}
            validationSchema={{
              required: 'This field is required',
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters',
              },
              validate: {
                hasUpperCase: (value: string) =>
                  /[A-Z]/.test(value) ||
                  'Password must contain at least one uppercase letter',
                hasLowerCase: (value: string) =>
                  /[a-z]/.test(value) ||
                  'Password must contain at least one lowercase letter',
                hasNumber: (value: string) =>
                  /\d/.test(value) ||
                  'Password must contain at least one number',
                hasSpecialChar: (value: string) =>
                  /[!@#$%^&*(),.?":{}|<>]/.test(value) ||
                  'Password must contain at least one special character',
              },
            }}
            errors={errors.password}
          />

          <InputField
            id="confirm-password"
            name="confirmPassword"
            type="password"
            label="Confirm password"
            placeholder="••••••••"
            register={register}
            validationSchema={{
              required: 'This field is required',
              validate: (value: string) =>
                value === watch('password') || 'Passwords do not match',
            }}
            errors={errors.confirmPassword}
          />

          <Button type="submit" loading={loading}>
            Register
          </Button>
        </form>

        {serverError && (
          <div className="mt-1 text-sm text-coral-700">{serverError}</div>
        )}
        <div className="mt-8">
          <div className="relative">
            <div
              aria-hidden="true"
              className="absolute inset-0 flex items-center"
            >
              <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex justify-center text-sm font-medium leading-6">
              <span className="bg-white px-6 text-gray-900">
                Or continue with
              </span>
            </div>
          </div>

          <div className="mt-6 grid grid-cols-2 gap-4">
            <SocialLoginButton
              onClick={() => handleOAuthLogin('microsoft')}
              iconSrc={MicrosoftIcon}
              altText="Microsoft Logo"
            >
              Microsoft
            </SocialLoginButton>
            <SocialLoginButton
              onClick={() => handleOAuthLogin('google')}
              iconSrc={GoogleIcon}
              altText="Google Icon"
            >
              Google
            </SocialLoginButton>
          </div>
        </div>
      </div>
    </>
  );
}
