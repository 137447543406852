import CryptoJS from 'crypto-js';
import { User, LoginFormInputs, RegisterFormInputs } from '../types';

export const hashPassword = (password: string): string => {
  const salt = import.meta.env.VITE_SALT;
  const combinedPassword = password + salt;
  const hashedPassword = CryptoJS.SHA256(combinedPassword).toString();
  return hashedPassword;
};

export async function loginUser(credentials: LoginFormInputs): Promise<User> {
  const response = await fetch('/api/auth/login', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: credentials.email,
      password: hashPassword(credentials.password),
      rememberMe: credentials.rememberMe,
    }),
    credentials: 'include',
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Login failed');
  }

  const data = await response.json();
  return data.user;
}

export async function logoutUser(): Promise<void> {
  await fetch('/api/auth/logout', {
    method: 'POST',
    credentials: 'include',
  });
}

export async function registerUser(data: RegisterFormInputs): Promise<User> {
  const response = await fetch('/api/auth/register', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: data.email,
      password: hashPassword(data.password),
      domain: data.domain,
    }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Registration failed');
  }

  const responseData = await response.json();
  return responseData.user;
}
