import { User, OrganisationUser } from '../types/User';
import axios from 'axios';
import {
  AddUserFormInputs,
  UpdateEmailFormInputs,
  EditUserFormInputs,
} from '../types';

export async function fetchCurrentUser(): Promise<User | null> {
  const response = await fetch('/api/auth/status', { credentials: 'include' });
  if (response.ok) {
    const data = await response.json();
    return data.user;
  }
  return null;
}

export async function fetchOrganisationUsers(
  organisationId: string
): Promise<OrganisationUser[]> {
  const response = await fetch(`/api/organisation/${organisationId}/users`, {
    credentials: 'include',
  });
  if (!response.ok) {
    throw new Error('Failed to fetch users');
  }
  return response.json();
}

export async function fetchOrganisationUserByEmail(
  organisationId: string,
  email: string
): Promise<OrganisationUser> {
  const response = await fetch(
    `/api/organisation/${organisationId}/user/email?email=${encodeURIComponent(email)}`,
    {
      credentials: 'include',
    }
  );

  if (!response.ok) {
    throw new Error('Failed to fetch user by email');
  }

  return response.json();
}

export const getUsers = async (
  organisationId: string
): Promise<OrganisationUser[]> => {
  const response = await axios.get(`/api/organisation/${organisationId}/users`);
  return response.data;
};

export const addUserApi = async (
  organisationId: string,
  data: AddUserFormInputs
): Promise<void> => {
  await axios.post(`/api/organisation/${organisationId}/users`, data);
};

export const updateUserApi = async (
  organisationId: string,
  data: EditUserFormInputs
): Promise<void> => {
  await axios.patch(
    `/api/organisation/${organisationId}/users/${data.user.id}`,
    data
  );
};

export const deleteUserApi = async (userId: string): Promise<void> => {
  await axios.delete(`/api/user/${userId}`);
};

export const reactivateUserApi = async (
  organisationId: string,
  userId: string
): Promise<void> => {
  await axios.post(
    `/api/organisation/${organisationId}/users/${userId}/reactivate`
  );
};

export const makeAccountOwnerApi = async (
  organisationId: string,
  userId: string
): Promise<void> => {
  await axios.post(
    `/api/organisation/${organisationId}/users/${userId}/make-account-owner`
  );
};

export const updateUserEmailApi = async (
  organisationId: string,
  data: UpdateEmailFormInputs
): Promise<void> => {
  await axios.patch(
    `/api/organisation/${organisationId}/users/${data.userId}/email`,
    { email: data.email }
  );
};

export const adminResetPasswordApi = async (
  organisationId: string,
  email: string
): Promise<void> => {
  await axios.post(`/api/auth/admin-reset-password`, { email, organisationId });
};

export const resendInvitationApi = async (
  organisationId: string,
  userId: string
): Promise<void> => {
  await axios.post('/api/auth/resend-invitation', {
    organisationId,
    userId,
  });
};

export const deleteUsersApi = async (
  organisationId: string,
  userIds: string[]
): Promise<void> => {
  await axios.delete(`/api/organisation/${organisationId}/users`, {
    data: { userIds },
  });
};
