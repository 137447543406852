import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '../../common/catalyst/dialog';
import { Field, Label } from '../../common/catalyst/fieldset';
import { Input } from '../../common/catalyst/input';
import { Select } from '../../common/catalyst/select';
import { Button } from '../../common/catalyst/button';
import { ErrorMessage } from '../../common/catalyst/fieldset';
import classNames from 'classnames';
import { Organisation } from '../../../types';
import { fetchOrganisationUserByEmail } from '../../../services/userService';
import { AddUserFormInputs } from '../../../types/teamMembers';

interface AddUserDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: AddUserFormInputs) => Promise<void>;
  organizationDomain: string;
  isSaving: boolean;
  currentOrganisation: Organisation | null;
}

export function AddUserDialog({
  isOpen,
  onClose,
  onSave,
  organizationDomain,
  isSaving,
  currentOrganisation,
}: AddUserDialogProps) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<AddUserFormInputs>();
  const [step, setStep] = useState(1);
  const [userExists, setUserExists] = useState(false);
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const team = watch('userRole.team', '');
  const currentRole = watch('userRole.role');
  const role = watch('userRole.role');

  const getRoleOptions = (email: string) => {
    if (email) {
      const emailDomain = email.split('@')[1];
      if (emailDomain === organizationDomain) {
        const internalRoles = ['User', 'Admin', 'Super Admin'];
        if (!internalRoles.includes(currentRole)) {
          setValue('userRole.role', 'User');
        }
        return internalRoles;
      } else {
        const externalRoles = ['Guest', 'Consultant'];
        if (!externalRoles.includes(currentRole)) {
          setValue('userRole.role', 'Guest');
        }
        return externalRoles;
      }
    } else {
      return ['User', 'Admin', 'Super Admin', 'Guest', 'Consultant'];
    }
  };

  const roleOptions = getRoleOptions(watch('user.email'));

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen, reset]);

  const teamOptions = [
    'Board',
    'Executive Leadership',
    'Operations',
    'Programs',
    'Marketing',
    'Fundraising',
    'Finance',
    'Information Technology',
    'Human Resources',
    'Volunteers',
    'Other',
  ];

  const getEmailPlaceholder = () => {
    if (organizationDomain) {
      return `user@${organizationDomain}`;
    } else {
      return 'user@example.com';
    }
  };

  const onSubmit = async (data: AddUserFormInputs) => {
    await onSave(data);
    setStep(3);
  };

  const handleNext = async () => {
    const email = watch('user.email');
    setIsFetchingUser(true);
    try {
      const user = await fetchOrganisationUserByEmail(
        currentOrganisation!.id,
        email
      );

      setUserExists(true);
      if (user.UserRole) {
        setError('user.email', {
          type: 'manual',
          message: 'User already exists in organisation',
        });
      } else {
        setValue('user.firstName', user.firstName);
        setValue('user.lastName', user.lastName);
        setValue('userRole.title', '');
        setValue('userRole.team', '');
        setValue('userRole.otherTeam', '');
        clearErrors('user.email');
        setStep(2);
      }
    } catch (error) {
      console.error(error);
      setUserExists(false);
      clearErrors('user.email');
      setStep(2);
    } finally {
      setIsFetchingUser(false);
    }
  };

  const handleCancel = () => {
    onClose();
    reset();
  };

  useEffect(() => {
    if (isOpen) {
      reset();
      setStep(1);
    }
  }, [isOpen, reset]);

  return (
    <Dialog open={isOpen} onClose={handleCancel}>
      <DialogTitle>
        {step <= 2
          ? 'Add user'
          : userExists
            ? 'User successfully added'
            : 'User successfully created'}
      </DialogTitle>
      <DialogDescription>
        {step === 1
          ? 'Enter the email address for the new user.'
          : step === 2
            ? 'Enter the details for the new user.'
            : userExists
              ? 'The user will receive an email containing an invitation to join your account.'
              : 'The user will receive a link to log in to their account for the first time.'}
      </DialogDescription>
      <DialogBody>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-12">
          {step === 1 && (
            <Field className="sm:col-span-4">
              <Label>Email</Label>
              <Input
                {...register('user.email', {
                  required: 'Email is required',
                })}
                type="email"
                placeholder={getEmailPlaceholder()}
                autoFocus
              />
              {errors.user?.email && (
                <ErrorMessage className="text-left">
                  {errors.user.email.message}
                </ErrorMessage>
              )}
            </Field>
          )}
          {step === 2 && (
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 border-b border-gray-900/10 pb-12 md:grid-cols-6">
              <Field className="sm:col-span-3">
                <Label>First name</Label>
                <Input
                  {...register('user.firstName', {
                    required: 'First name is required',
                  })}
                  placeholder="First name"
                  disabled={userExists}
                />
                {errors.user?.firstName && (
                  <ErrorMessage className="text-left">
                    {errors.user.firstName.message}
                  </ErrorMessage>
                )}
              </Field>

              <Field className="sm:col-span-3">
                <Label>Last name</Label>
                <Input
                  {...register('user.lastName', {
                    required: 'Last name is required',
                  })}
                  placeholder="Last name"
                  disabled={userExists}
                />
                {errors.user?.lastName && (
                  <ErrorMessage className="text-left">
                    {errors.user.lastName.message}
                  </ErrorMessage>
                )}
              </Field>

              <Field className="sm:col-span-2">
                <Label>Role</Label>
                <Select
                  {...register('userRole.role', {
                    required: 'Role is required',
                  })}
                >
                  {roleOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
                {errors.userRole?.role && (
                  <ErrorMessage className="text-left">
                    {errors.userRole?.role.message}
                  </ErrorMessage>
                )}
              </Field>

              <Field className="sm:col-span-4">
                <Label>Title</Label>
                <Input
                  {...register('userRole.title', {
                    required: 'Title is required',
                  })}
                  placeholder="Title"
                />
                {errors.userRole?.title && (
                  <ErrorMessage className="text-left">
                    {errors.userRole?.title.message}
                  </ErrorMessage>
                )}
              </Field>

              {currentRole !== 'Consultant' && (
                <>
                  <Field
                    className={classNames({
                      'sm:col-span-6': team !== 'Other',
                      'sm:col-span-3': team === 'Other',
                    })}
                  >
                    <Label>Team</Label>
                    <Select
                      {...register('userRole.team', {
                        required:
                          role !== 'Consultant' ? 'Team is required' : false,
                      })}
                    >
                      <option value="">Select a team</option>
                      {teamOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                    {errors.userRole?.team && (
                      <ErrorMessage className="text-left">
                        {errors.userRole.team.message}
                      </ErrorMessage>
                    )}
                  </Field>

                  {team === 'Other' && (
                    <Field className="sm:col-span-3">
                      <Label>Other team</Label>
                      <Input
                        {...register('userRole.otherTeam', {
                          required:
                            team === 'Other' && role !== 'Consultant'
                              ? 'Please specify your team'
                              : false,
                        })}
                        placeholder="Please specify your team"
                      />
                      {errors.userRole?.otherTeam && (
                        <ErrorMessage className="text-left">
                          {errors.userRole.otherTeam.message}
                        </ErrorMessage>
                      )}
                    </Field>
                  )}
                </>
              )}
            </div>
          )}
        </form>
      </DialogBody>
      <DialogActions>
        {step === 1 ? (
          <>
            <Button plain onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleNext} disabled={isFetchingUser}>
              {isFetchingUser ? (
                <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              ) : (
                'Next'
              )}
            </Button>
          </>
        ) : step === 2 ? (
          <>
            <Button plain onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isSaving}
              className="min-h-[38px] flex items-center justify-center"
            >
              {isSaving ? (
                <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              ) : (
                'Save'
              )}
            </Button>
          </>
        ) : (
          <Button onClick={handleCancel}>Close</Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
