import { useState, useEffect } from 'react';
import { PageContainer } from '../../components/layout/PageContainer';
import { Heading } from '../../components/common/catalyst/heading';
import { Tab } from '@headlessui/react';
import { BuildingOfficeIcon, UsersIcon } from '@heroicons/react/20/solid';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';

const tabs = [
  {
    name: 'Organisation',
    icon: BuildingOfficeIcon,
    path: '/settings/organisation',
  },
  { name: 'Team', icon: UsersIcon, path: '/settings/team' },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function Account() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const index = tabs.findIndex((tab) => tab.path === location.pathname);
    if (index !== -1) {
      setSelectedIndex(index);
    }
  }, [location]);

  const handleTabChange = (index: number) => {
    setSelectedIndex(index);
    navigate(tabs[index].path);
  };

  return (
    <PageContainer>
      <Heading className="mb-2">Account settings</Heading>
      <Tab.Group selectedIndex={selectedIndex} onChange={handleTabChange}>
        <Tab.List className="flex space-x-4 border-b border-gray-200">
          {tabs.map((tab) => (
            <Tab
              key={tab.name}
              className={({ selected }) =>
                classNames(
                  'group inline-flex items-center px-1 py-4 border-b-2 font-medium text-sm',
                  selected
                    ? 'border-zinc-500 text-zinc-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                )
              }
            >
              {tab.name}
            </Tab>
          ))}
        </Tab.List>
        <div className="w-full mt-8">
          <Outlet />
        </div>
      </Tab.Group>
    </PageContainer>
  );
}
