import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Fieldset,
  Field,
  Label,
  Description,
  ErrorMessage,
} from '../../../../common/catalyst/fieldset';
import { Radio, RadioGroup } from '@headlessui/react';
import { OnboardingFormInputs } from '../../../../../types/OnboardingFormInputs';

const organisationSizes = [
  { value: 'startup', name: '1-9 employees', description: 'Startup' },
  { value: 'small', name: '10-49 employees', description: 'Small' },
  { value: 'medium', name: '50-249 employees', description: 'Medium' },
  { value: 'large', name: '250-999 employees', description: 'Large' },
  { value: 'enterprise', name: '1000+ employees', description: 'Enterprise' },
];

export const OrganisationSizeStep: React.FC = () => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<OnboardingFormInputs>();
  const selectedSize = watch('organisation.size');

  return (
    <div className="flex items-start justify-start h-full w-full p-1">
      <Fieldset className="w-full max-w-md">
        <Field>
          <Label className="text-left text-2xl">Organisation size</Label>
          <Description className="text-left">
            Please select the size of your organisation.
          </Description>
          <RadioGroup
            value={selectedSize}
            onChange={(value) =>
              setValue('organisation.size', value, { shouldValidate: true })
            }
            className="mt-4 space-y-4"
          >
            {organisationSizes.map((size) => (
              <Radio
                key={size.value}
                value={size.value}
                className="group relative block cursor-pointer rounded-lg border border-gray-300 bg-white px-6 py-4 shadow-sm focus:outline-none data-[focus]:border-zinc-600 data-[focus]:ring-2 data-[focus]:ring-zinc-600"
              >
                {({ checked }) => (
                  <>
                    <span className="flex items-center">
                      <span className="flex flex-col text-sm">
                        <span className="font-medium text-gray-900">
                          {size.name}
                        </span>
                        <span className="text-gray-500">
                          {size.description}
                        </span>
                      </span>
                    </span>
                    <span
                      className={`${
                        checked ? 'border-zinc-600' : 'border-transparent'
                      } absolute -inset-px rounded-lg border-2 pointer-events-none`}
                      aria-hidden="true"
                    />
                  </>
                )}
              </Radio>
            ))}
          </RadioGroup>
          <input
            type="hidden"
            {...register('organisation.size', {
              required: 'Please select an organisation size',
            })}
          />
          {errors.organisation?.size && (
            <ErrorMessage className="text-left">
              {errors.organisation.size.message}
            </ErrorMessage>
          )}
        </Field>
      </Fieldset>
    </div>
  );
};
