import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useCurrentUser } from '../../hooks/useCurrentUser.ts';

import { InputField } from '../../components/common/InputField.tsx';
import { Button } from '../../components/common/Button.tsx';
import { SocialLoginButton } from '../../components/common/SocialLoginButton.tsx';

import { LoginFormInputs } from '../../types';

import MicrosoftIcon from '/assets/img/microsoft-logo.svg';
import GoogleIcon from '/assets/img/google-logo.svg';
import { handleOAuthLogin } from '../../utils/oauthLogin.ts';

export default function Login() {
  const { login } = useCurrentUser();
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState('');

  const { register, handleSubmit, formState, reset } =
    useForm<LoginFormInputs>();
  const { errors } = formState;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const error = params.get('error');
    if (error) {
      let errorMessage = '';
      switch (error) {
        case 'authentication_failed':
          errorMessage = 'Authentication failed';
          break;
        case 'domain_not_allowed':
          errorMessage = 'This domain is not approved for registration';
          break;
        case 'invitation_required':
          errorMessage = 'You must receive an invite to join this account';
          break;
        case 'server_error':
          errorMessage = 'A server error has occurred';
          break;
        default:
          errorMessage = 'An unknown error has occurred.';
      }
      setServerError(errorMessage);
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate]);

  const onSubmit = async (data: LoginFormInputs) => {
    setLoading(true);
    setServerError('');
    try {
      await login(data);
      reset();
    } catch (error) {
      console.error('Login failed:', error);
      setServerError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <h2 className="text-2xl font-semibold leading-9 text-graphite-900">
          Log in to your account
        </h2>
        <p className="mt-2 text-sm leading-6 text-graphite-900">
          Not a member yet?{' '}
          <Link
            to="/register"
            className="font-semibold text-pine-600 hover:text-pine-500"
          >
            Create an account
          </Link>
        </p>
      </div>
      <div className="mt-8">
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className="space-y-6"
        >
          <InputField
            id="email"
            name="email"
            type="text"
            label="Email address"
            placeholder="jane@smith.com"
            autofill="email"
            register={register}
            autoFocus={true}
            validationSchema={{
              required: 'This field is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            }}
            errors={errors.email}
          />

          <InputField
            id="password"
            name="password"
            type="password"
            label="Password"
            placeholder="••••••••"
            autofill="password"
            register={register}
            validationSchema={{
              required: 'This field is required',
            }}
            errors={errors.password}
          />

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                {...register('rememberMe')}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-pine-600 focus:ring-pine-600"
              />
              <label
                htmlFor="remember-me"
                className="ml-3 block text-sm leading-6 text-gray-700"
              >
                Remember me
              </label>
            </div>

            <div className="text-sm leading-6">
              <Link
                to="/reset-password"
                className="font-semibold text-pine-600 hover:text-pine-500"
              >
                Forgot password?
              </Link>
            </div>
          </div>

          <Button type="submit" loading={loading}>
            Log in
          </Button>
        </form>

        {serverError && (
          <div className="mt-1 text-sm text-coral-700">{serverError}</div>
        )}

        <div className="mt-8">
          <div className="relative">
            <div
              aria-hidden="true"
              className="absolute inset-0 flex items-center"
            >
              <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex justify-center text-sm font-medium leading-6">
              <span className="bg-white px-6 text-gray-900">
                Or continue with
              </span>
            </div>
          </div>

          <div className="mt-6 grid grid-cols-2 gap-4">
            <SocialLoginButton
              onClick={() => handleOAuthLogin('microsoft')}
              iconSrc={MicrosoftIcon}
              altText="Microsoft Logo"
            >
              Microsoft
            </SocialLoginButton>
            <SocialLoginButton
              onClick={() => handleOAuthLogin('google')}
              iconSrc={GoogleIcon}
              altText="Google Icon"
            >
              Google
            </SocialLoginButton>
          </div>
        </div>
      </div>
    </>
  );
}
