import { useQuery } from '@tanstack/react-query';
import { fetchUserOrganisations } from '../services/organisationService';
import { Organisation } from '../types/Organisation';
import { useCurrentUser } from './useCurrentUser';

export function useCurrentUserOrganisations() {
  const { user, isAuthenticated } = useCurrentUser();

  const {
    data: organisations,
    isLoading,
    isError,
  } = useQuery<Organisation[]>({
    queryKey: ['currentUserOrganisations', user?.id],
    queryFn: () => fetchUserOrganisations(user?.id || ''),
    enabled: isAuthenticated && !!user?.id,
  });

  return {
    organisations,
    isLoading,
    isError,
  };
}
