import { Heading } from '../../components/common/catalyst/heading';
import { ComingSoon } from '../../components/common/ComingSoon';
import { ComingSoonPageContainer } from '../../components/layout/ComingSoonPageContainer';

export default function Staff() {
  return (
    <ComingSoonPageContainer>
      <Heading className="mb-2">Staff Management</Heading>
      <ComingSoon
        title="Manage Your Team Effectively"
        description="Soon, you'll be able to view and manage individual staff members, enhancing your organization's HR capabilities."
      >
        <li>View detailed profiles of each staff member</li>
        <li>Track roles, responsibilities, and performance</li>
        <li>Assign training and development resources</li>
        <li>Facilitate better communication and collaboration</li>
      </ComingSoon>
    </ComingSoonPageContainer>
  );
}
