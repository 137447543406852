import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { Button } from '../../components/common/Button';
import { useCurrentUserRole } from '../../hooks/useCurrentUserRole';
import { useCurrentUserRoles } from '../../hooks/useCurrentUserRoles';

export default function AcceptInvitation() {
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState('');
  const [accepted, setAccepted] = useState(false);
  const [organisationName, setOrganisationName] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const { switchRole } = useCurrentUserRole();
  const { refetch } = useCurrentUserRoles();

  // Get token from query parameters
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  useEffect(() => {
    if (!token) {
      setServerError('Invalid invitation link.');
    } else {
      // Fetch invitation details
      const fetchInvitationDetails = async () => {
        try {
          const response = await fetch(
            `/api/user-role/invitation-details?token=${token}`,
            {
              method: 'GET',
              headers: { 'Content-Type': 'application/json' },
            }
          );

          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(
              errorData.message || 'Failed to fetch invitation details'
            );
          }

          const data = await response.json();
          setOrganisationName(data.organisationName);
        } catch (error) {
          console.error('Error fetching invitation details:', error);
          setServerError((error as Error).message);
        }
      };

      fetchInvitationDetails();
    }
  }, [token]);

  const handleAccept = async () => {
    setLoading(true);
    setServerError('');
    try {
      console.log('Starting invitation acceptance process...');

      const response = await fetch('/api/user-role/accept-invitation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token }),
        credentials: 'include',
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to accept invitation');
      }

      const data = await response.json();
      console.log('Invitation accepted, received data:', data);
      setOrganisationName(data.organisation.name);
      setAccepted(true);

      // Refetch user roles to include the new organisation
      console.log('Refetching user roles...');
      const refetchResult = await refetch();
      const updatedUserRoles = refetchResult.data;

      if (!updatedUserRoles) {
        console.warn('No user roles data received after refetch');
        return;
      }

      console.log('Current user roles after refetch:', updatedUserRoles);
      console.log('Looking for organisation ID:', data.organisation.id);

      // Find the index of the new organisation using the updated roles
      const newOrgIndex = updatedUserRoles.findIndex(
        (role) => role.organisationId === data.organisation.id
      );

      console.log('Found organisation at index:', newOrgIndex);
      console.log('Role details at index:', updatedUserRoles[newOrgIndex]);

      if (newOrgIndex !== -1) {
        // Switch to the new organisation
        console.log(`switching to role index ${newOrgIndex}`);
        switchRole(newOrgIndex);
      } else {
        console.warn('Could not find the new organisation in user roles');
      }
    } catch (error) {
      console.error('Accept failed:', error);
      setServerError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  if (accepted) {
    return (
      <div>
        <h1 className="text-2xl font-semibold">Invitation Accepted</h1>
        <p className="mt-4">
          You have successfully accepted the invitation to join{' '}
          {organisationName}. You can now access the organisation.
        </p>
        <div className="mt-6">
          <Button onClick={() => navigate('/')}>Go to Dashboard</Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      {organisationName ? (
        <>
          <h1 className="text-2xl font-semibold">Join {organisationName}</h1>
          <p className="mt-4">
            You&apos;ve been invited to join {organisationName} on the Zentient
            platform. Click the button below to confirm your acceptance.
          </p>
        </>
      ) : (
        <h1 className="text-2xl font-semibold">Accept Invitation</h1>
      )}
      {serverError && <div className="mt-4 text-red-600">{serverError}</div>}
      <div className="mt-6">
        <Button onClick={handleAccept} loading={loading}>
          Accept
        </Button>
      </div>
    </div>
  );
}
