import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Fieldset,
  Field,
  Label,
  Description,
  ErrorMessage,
} from '../../../../common/catalyst/fieldset';
import { Input } from '../../../../common/catalyst/input';
import { OnboardingFormInputs } from '../../../../../types/OnboardingFormInputs';

export const NameStep: React.FC = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<OnboardingFormInputs>();

  return (
    <div className="flex items-start justify-start h-full w-full p-1">
      <Fieldset className="w-full max-w-md">
        <Field>
          <Label className="text-left text-2xl">Your name</Label>
          <div className="flex space-x-4">
            <div className="flex-1">
              <Description className="text-left">First</Description>
              <Input
                {...register('user.firstName', {
                  required: 'First name is required',
                  minLength: {
                    value: 2,
                    message: 'First name must contain at least two characters',
                  },
                })}
                type="text"
                placeholder="First name"
                className="w-full mt-2"
                autoFocus
              />
              {errors.user?.firstName && (
                <ErrorMessage className="text-left">
                  {errors.user.firstName.message}
                </ErrorMessage>
              )}
            </div>
            <div className="flex-1">
              <Description className="text-left">Last</Description>
              <Input
                {...register('user.lastName', {
                  required: 'Last name is required',
                  minLength: {
                    value: 2,
                    message: 'Last name must contain at least two characters',
                  },
                })}
                type="text"
                placeholder="Last name"
                className="w-full mt-2"
              />
              {errors.user?.lastName && (
                <ErrorMessage className="text-left">
                  {errors.user.lastName.message}
                </ErrorMessage>
              )}
            </div>
          </div>
        </Field>
      </Fieldset>
    </div>
  );
};
