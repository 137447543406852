import React from 'react';
import { GenericStep } from './GenericStep';

export const OrganisationNameStep: React.FC = () => (
  <GenericStep
    title="Organisation name"
    description="Please enter the name of your organisation."
    fieldName="organisation.name"
    placeholder="My Super Organisation"
    validation={{
      required: 'This field is required',
      minLength: {
        value: 2,
        message: 'Organisation name must contain at least two characters',
      },
    }}
  />
);
