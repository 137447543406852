import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { loginUser, logoutUser, registerUser } from '../services/authService';
import { fetchCurrentUser } from '../services/userService';
import { useTokenRefresh } from './useTokenRefresh';
import { User, LoginFormInputs, RegisterFormInputs } from '../types';
import ms from 'ms';

export function useCurrentUser() {
  const queryClient = useQueryClient();
  const { clientTokenInterval, scheduleTokenRefresh, clearTokenRefresh } =
    useTokenRefresh();

  const {
    data: user,
    isLoading,
    isError,
  } = useQuery<User | null>({
    queryKey: ['currentUser'],
    queryFn: fetchCurrentUser,
    retry: false,
    staleTime: clientTokenInterval,
    gcTime: ms('2h'),
    meta: {
      ErrorMessage: 'Failed to authenticate user',
    },
  });

  const loginMutation = useMutation<User, Error, LoginFormInputs>({
    mutationFn: (credentials) => loginUser(credentials),
    retry: false,
    onSuccess: (data) => {
      queryClient.setQueryData(['currentUser'], data);
      scheduleTokenRefresh();
    },
  });

  const logoutMutation = useMutation<void, Error, void>({
    mutationFn: logoutUser,
    onSuccess: () => {
      queryClient.setQueryData(['currentUser'], null);
      clearTokenRefresh();
      queryClient.clear();
    },
  });

  const registerMutation = useMutation<User, Error, RegisterFormInputs>({
    mutationFn: (data) => registerUser(data),
    onSuccess: (data) => {
      queryClient.setQueryData(['currentUser'], data);
      scheduleTokenRefresh();
    },
  });

  return {
    user,
    isAuthenticated: !!user,
    isLoading,
    isError,
    login: loginMutation.mutateAsync,
    logout: logoutMutation.mutateAsync,
    register: registerMutation.mutateAsync,
  };
}
