import React from 'react';
import { useFormContext, get } from 'react-hook-form';
import {
  Fieldset,
  Field,
  Label,
  Description,
  ErrorMessage,
} from '../../../../common/catalyst/fieldset';
import { Radio, RadioGroup } from '@headlessui/react';
import { OnboardingFormInputs } from '../../../../../types/OnboardingFormInputs';

const organisationTypes = [
  {
    value: 'not-for-profit',
    name: 'Not-for-profit organisation',
    description: 'Organisation that does not seek profit',
  },
  {
    value: 'social enterprise',
    name: 'Social enterprise',
    description: 'Enterprise that aims to have a positive social impact',
  },
  {
    value: 'company',
    name: 'Company',
    description: 'Profit-seeking organisation',
  },
  { value: 'other', name: 'Other', description: 'Other type of organisation' },
];

export const OrganisationTypeStep: React.FC = () => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<OnboardingFormInputs>();
  const selectedType = watch('organisation.type') || '';

  return (
    <div className="flex items-start justify-start h-full w-full p-1">
      <Fieldset className="w-full max-w-md">
        <Field>
          <Label className="text-left text-2xl">Organisation type</Label>
          <Description className="text-left">
            Please select the type of your organisation.
          </Description>
          <RadioGroup
            value={selectedType}
            onChange={(value) =>
              setValue('organisation.type', value, { shouldValidate: true })
            }
            className="mt-4 space-y-4"
          >
            {organisationTypes.map((type) => (
              <Radio
                key={type.value}
                value={type.value}
                className="group relative block cursor-pointer rounded-lg border border-gray-300 bg-white px-6 py-4 shadow-sm focus:outline-none data-[focus]:border-zinc-600 data-[focus]:ring-2 data-[focus]:ring-zinc-600"
              >
                {({ checked }) => (
                  <>
                    <span className="flex items-center">
                      <span className="flex flex-col text-sm">
                        <span className="font-medium text-gray-900">
                          {type.name}
                        </span>
                        <span className="text-gray-500">
                          {type.description}
                        </span>
                      </span>
                    </span>
                    <span
                      className={`${
                        checked ? 'border-zinc-600' : 'border-transparent'
                      } absolute -inset-px rounded-lg border-2 pointer-events-none`}
                      aria-hidden="true"
                    />
                  </>
                )}
              </Radio>
            ))}
          </RadioGroup>
          <input
            type="hidden"
            {...register('organisation.type', {
              required: 'Please select an organisation type',
            })}
          />
          {get(errors, 'organisation.type')?.message && (
            <ErrorMessage className="text-left">
              {get(errors, 'organisation.type')?.message}
            </ErrorMessage>
          )}
        </Field>
      </Fieldset>
    </div>
  );
};
