import { useMutation, useQueryClient } from '@tanstack/react-query';
import { onboardUser as onboardUserService } from '../services/onboardingService';
import { OnboardingResponse } from '../types/OnboardingResponse';
import { OnboardingFormInputs } from '../types/OnboardingFormInputs';
import { OnboardingData, OrganisationData } from '../types/OnboardingData';

export function useOnboarding() {
  const queryClient = useQueryClient();

  const transformToOnboardingData = (
    formData: OnboardingFormInputs
  ): OnboardingData => {
    const { organisation, ...rest } = formData;
    const causesArray = organisation?.causes
      ? Object.keys(organisation.causes).filter(
          (causeId) => organisation.causes[causeId]
        )
      : [];

    const transformedOrganisation: OrganisationData | null = organisation
      ? {
          ...organisation,
          causes: causesArray, // causes as string[]
        }
      : null;

    return {
      ...rest,
      organisation: transformedOrganisation,
    };
  };

  const onboardingMutation = useMutation<
    OnboardingResponse,
    Error,
    OnboardingFormInputs
  >({
    mutationFn: (formData: OnboardingFormInputs) => {
      const transformedData = transformToOnboardingData(formData);
      return onboardUserService(transformedData);
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['currentUser'], data.user);
      queryClient.setQueryData(['currentUserRoles'], data.userRole);
      if (data.organisation) {
        queryClient.setQueryData(
          ['organisation', data.organisation.id],
          data.organisation
        );
      }

      queryClient.invalidateQueries({
        queryKey: ['currentUser'],
        refetchType: 'all',
      });
      queryClient.invalidateQueries({
        queryKey: ['currentUserRoles'],
        refetchType: 'all',
      });
      if (data.organisation) {
        queryClient.invalidateQueries({
          queryKey: ['organisation', data.organisation.id],
          refetchType: 'all',
        });
      }
    },
  });

  return {
    onboardUser: onboardingMutation.mutateAsync,
  };
}
