import './App.css';

// import { useState, useEffect } from 'react';

import { Route, Routes, Navigate } from 'react-router-dom';

import ProtectedRoute from './components/specific/control/ProtectedRoute';
import RequiresOnboarding from './components/specific/control/RequiresOnboarding';

import AuthRoute from './components/layout/AuthRoute';
// import { OnboardingModal } from './modals/OnboardingModal';

import Register from './pages/auth/Register';
import LogIn from './pages/auth/LogIn';
import LogOut from './pages/auth/LogOut';
import PasswordReset from './pages/auth/PasswordReset';
import PasswordUpdate from './pages/auth/PasswordUpdate';
import AcceptInvitation from './pages/auth/AcceptInvitation';

import Home from './pages/general/Home';
import Wiki from './pages/general/Wiki';
import Passwords from './pages/general/Passwords';
import Search from './pages/admin/Search';
import Dashboard from './pages/admin/Dashboard';
import Staff from './pages/admin/Staff';
import Technology from './pages/admin/Technology';
import Support from './pages/admin/Support';
import Surveying from './pages/strategy/Surveying';
import Architecture from './pages/strategy/Architecture';
import Building from './pages/strategy/Building';
import Strategy from './pages/admin/Strategy';
import Account from './pages/admin/AccountSettings';
import Notifications from './pages/admin/Notifications';

import { SidebarLayout } from './components/common/catalyst/sidebar-layout';
import { Navbar } from './components/common/catalyst/navbar';
import { PrimaryNavigation } from './components/layout/PrimaryNavigation';

import { OrganisationDetails } from './components/specific/AccountSettingsTabs/OrganisationDetails';
import { TeamMembers } from './components/specific/AccountSettingsTabs/TeamMembers';

function AppContent() {
  return (
    <Routes>
      <Route element={<AuthRoute />}>
        <Route path="/login" element={<LogIn />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset-password" element={<PasswordReset />} />
        <Route path="/update-password" element={<PasswordUpdate />} />
        <Route path="/accept-invitation" element={<AcceptInvitation />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route element={<RequiresOnboarding />}>
          <Route
            path="/*"
            element={
              <SidebarLayout
                navbar={<Navbar />}
                sidebar={<PrimaryNavigation />}
              >
                <Routes>
                  <Route index element={<Home />} />
                  <Route path="/wiki" element={<Wiki />} />
                  <Route path="/passwords" element={<Passwords />} />
                  <Route path="/search" element={<Search />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/staff" element={<Staff />} />
                  <Route path="/technology" element={<Technology />} />
                  <Route path="/support" element={<Support />} />
                  <Route path="/surveying" element={<Surveying />} />
                  <Route path="/architecture" element={<Architecture />} />
                  <Route path="/building" element={<Building />} />
                  <Route path="/strategy" element={<Strategy />} />
                  <Route
                    element={<ProtectedRoute requiredPermission="admin" />}
                  >
                    <Route path="/settings" element={<Account />}>
                      <Route
                        index
                        element={
                          <Navigate to="/settings/organisation" replace />
                        }
                      />
                      <Route
                        path="organisation"
                        element={<OrganisationDetails />}
                      />
                      <Route path="team" element={<TeamMembers />} />
                    </Route>
                  </Route>
                  <Route path="/notifications" element={<Notifications />} />
                </Routes>
              </SidebarLayout>
            }
          />
        </Route>
        <Route path="/logout" element={<LogOut />} />
      </Route>
    </Routes>
  );
}

function App() {
  return <AppContent />;
}

export default App;

{
  /* <UserProvider>
<UserRoleProvider>
  <OrganisationProvider>
    <AppContent />
  </OrganisationProvider>
</UserRoleProvider>
</UserProvider> */
}
