import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '../../common/catalyst/dialog';
import { Field, Label } from '../../common/catalyst/fieldset';
import { Input } from '../../common/catalyst/input';
import { Button } from '../../common/catalyst/button';
import { ErrorMessage } from '../../common/catalyst/fieldset';
import {
  Alert,
  AlertTitle,
  AlertDescription,
  AlertActions,
} from '../../common/catalyst/alert';
import { UpdateEmailFormInputs } from '../../../types';
import { fetchOrganisationUserByEmail } from '../../../services/userService';

interface UpdateEmailDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: UpdateEmailFormInputs) => void;
  isSaving: boolean;
  currentEmail: string;
  userId: string;
  userRole: string;
  organisationDomain: string;
  organisationId: string;
}

export function UpdateEmailDialog({
  isOpen,
  onClose,
  onSave,
  isSaving,
  currentEmail,
  userId,
  userRole,
  organisationDomain,
  organisationId,
}: UpdateEmailDialogProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<{ email: string }>();
  const [showAlert, setShowAlert] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);

  const validateEmail = async (email: string) => {
    if (email === currentEmail) {
      setError('email', {
        type: 'manual',
        message: 'New email must be different from the current email',
      });
      return false;
    }

    const emailDomain = email.split('@')[1];

    if (
      (userRole === 'User' ||
        userRole === 'Admin' ||
        userRole === 'Super Admin') &&
      emailDomain !== organisationDomain
    ) {
      setError('email', {
        type: 'manual',
        message: `Email for user must be within the organisation's domain (${organisationDomain})`,
      });
      return false;
    }

    if (
      (userRole === 'Guest' || userRole === 'Consultant') &&
      emailDomain === organisationDomain
    ) {
      setError('email', {
        type: 'manual',
        message: `Email must be external for role ${userRole}`,
      });
      return false;
    }

    setIsCheckingEmail(true);
    try {
      const existingUser = await fetchOrganisationUserByEmail(
        organisationId,
        email
      );
      if (existingUser) {
        setError('email', {
          type: 'manual',
          message: 'This email is already in use within the organisation',
        });
        setIsCheckingEmail(false);
        return false;
      }
    } catch {
      setIsCheckingEmail(false);
      return true;
    }
    setIsCheckingEmail(false);
    return true;
  };

  const onSubmit = async (data: { email: string }) => {
    clearErrors();
    const isValid = await validateEmail(data.email);
    if (isValid) {
      setNewEmail(data.email);
      setShowAlert(true);
    }
  };

  const confirmUpdate = async () => {
    await onSave({ userId, email: newEmail });
    setShowAlert(false);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Update Email</DialogTitle>
      <DialogDescription>
        Enter the new email address for the user.
      </DialogDescription>
      <DialogBody>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <Field>
            <Label>New Email</Label>
            <Input
              {...register('email', { required: 'Email is required' })}
              type="email"
              placeholder="user@example.com"
              defaultValue={currentEmail}
              autoFocus
            />
            {errors.email && (
              <ErrorMessage className="text-left">
                {errors.email.message}
              </ErrorMessage>
            )}
          </Field>
        </form>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={isSaving || isCheckingEmail}
        >
          {isCheckingEmail
            ? 'Checking...'
            : isSaving
              ? 'Updating...'
              : 'Update'}
        </Button>
      </DialogActions>
      {showAlert && (
        <Alert size="md" open={showAlert} onClose={() => setShowAlert(false)}>
          <AlertTitle>Change email address?</AlertTitle>
          <AlertDescription>
            Are you sure you want to change the email address to {newEmail}? The
            user will receive a verification email to this new address.
          </AlertDescription>
          <AlertActions>
            <Button plain onClick={() => setShowAlert(false)}>
              Cancel
            </Button>
            <Button onClick={confirmUpdate}>Confirm</Button>
          </AlertActions>
        </Alert>
      )}
    </Dialog>
  );
}
