import { Heading } from '../../components/common/catalyst/heading';
import { ComingSoon } from '../../components/common/ComingSoon';
import { ComingSoonPageContainer } from '../../components/layout/ComingSoonPageContainer';

export default function Home() {
  return (
    <ComingSoonPageContainer>
      <Heading className="mb-2">Home</Heading>
      <ComingSoon
        title="Your New IT Hub"
        description="Soon, this will be your organization's central hub for accessing and managing all your digital tools and resources."
      >
        <li>Access all your organization&apos;s tools from one place</li>
        <li>Get personalized quick actions based on your role</li>
        <li>See important notifications and updates at a glance</li>
        <li>Navigate your digital workspace effortlessly</li>
      </ComingSoon>
    </ComingSoonPageContainer>
  );
}
