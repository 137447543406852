import React from 'react';
import clsx from 'clsx';
import { Text } from './catalyst/text';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

interface ComingSoonProps {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  className?: string;
}

export const ComingSoon: React.FC<ComingSoonProps> = ({
  title = 'Coming Soon',
  description = 'This feature is under development and will be available soon.',
  children,
  className,
}) => {
  return (
    <div className="absolute inset-0 flex items-center justify-center backdrop-blur-[2px] bg-white/70 dark:bg-zinc-900/70 rounded-lg">
      <div
        className={clsx(
          'w-full max-w-md rounded-2xl bg-white p-[--gutter] shadow-lg ring-1 ring-zinc-950/10 [--gutter:theme(spacing.8)] dark:bg-zinc-900 dark:ring-white/10',
          className
        )}
      >
        <h3 className="text-balance text-lg/6 font-semibold text-zinc-950 sm:text-base/6 dark:text-white">
          {title}
        </h3>
        <Text className="mt-2 text-pretty">{description}</Text>
        {children && (
          <ul className="mt-4 space-y-2">
            {React.Children.map(children, (child) => (
              <li className="flex items-center gap-2">
                <CheckCircleIcon className="h-5 w-5 flex-none text-pine-500" />
                <Text className="text-zinc-600 dark:text-zinc-400">
                  {child}
                </Text>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
