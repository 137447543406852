import { OnboardingData } from '../types/OnboardingData';

export const onboardUser = async (formData: OnboardingData) => {
  const response = await fetch(
    `/api/organisation/${formData.organisation?.id}/onboarding`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    }
  );

  if (!response.ok) {
    throw new Error('Onboarding failed');
  }

  return response.json();
};
